import { create } from 'zustand';
import { getEvents } from '@libs/api/shifts';
import { ShiftList } from '@libs/models/shifts/shift';
import { DateHelper } from '@bryntum/schedulerpro';
import { Dayjs } from 'dayjs';

type DateFilterValue = { from: Dayjs; to: Dayjs };

type State = {
  loading: boolean;
  drawerOpen: boolean;
  rotationId: string;
  drawerMode: 'add' | 'edit';
  initiated: boolean;
  shiftsToShare: ShiftList[];
  loadSharableShifts: (dateFilter: DateFilterValue | null, statusFilter: string[]) => void;
  openShiftShareDrawer: (id?: string) => void;
  closeShiftShareDrawer: () => void;
};

export const useShareDrawerStore = create<State>()((set, get) => ({
  loading: false,
  drawerOpen: false,
  drawerMode: 'add',
  initiated: false,
  rotationId: '',
  shiftsToShare: [],
  loadSharableShifts: async (dateFilter, statusFilter) => {
    set({ loading: true });

    const statuses = statusFilter.length
      ? { key: 'statuses', value: statusFilter }
      : { key: 'statuses', value: ['Unassigned', 'Pending'] };
    try {
      const startDate = dateFilter
        ? dateFilter?.from.toDate().toISOString()
        : DateHelper.startOf(new Date(), 'week', undefined, 1).toISOString();
      const endDate = dateFilter
        ? dateFilter.to.toDate().toISOString()
        : DateHelper.add(DateHelper.startOf(new Date(), 'week', undefined, 1), 7, 'days').toISOString();

      const resp = await getEvents(startDate, endDate, [
        {
          key: 'staffingtypes',
          value: ['External', 'Any'],
        },
        statuses,
      ]);
      set({
        loading: false,
        shiftsToShare: resp.data,
      });
    } finally {
      set({ loading: false, initiated: true });
    }
  },
  openShiftShareDrawer: (id) => {
    set({ drawerMode: id ? 'edit' : 'add', rotationId: id, drawerOpen: true });
  },
  closeShiftShareDrawer: () => {
    set({ drawerOpen: false });
  },
}));
