import { useState } from 'react';
import { FormattedMessage } from 'react-intl';
import Drawer from '@mui/material/Drawer';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import { useShiftsListStore } from '@libs/store/shifts';
import { Box, Flex } from '@atoms/layout';
import { GeneralInfo } from './shiftDetails/generalInfo';
import { ShiftNotes } from './shiftDetails/shiftNotes';
import { ShiftBilling } from './shiftDetails/shiftBilling';
import { ShiftHistory } from './shiftDetails/shiftHistoryLog';
import { ComingSoonSpacer } from '@organisms/dataDisplay';
import { AnimatedTabPanel } from '@molecules/layout';

export function ShiftDetailsDrawer() {
  const { showDetails, closeShiftDetails, currentShift } = useShiftsListStore();
  const open = showDetails;
  const [tab, setTab] = useState(0);

  const pageEnablingMap = {
    generalInfo: true,
    shiftNotes: true,
    shiftsBilling: false,
    shiftHistory: false,
  };

  return (
    <Drawer open={open} onClose={() => closeShiftDetails()} anchor="right">
      <Flex width={{ xs: '300px', sm: '600px' }} py={2} column height="100%">
        <Box px={2}>
          <Tabs value={tab} onChange={(_, value) => setTab(value)} aria-label="shifts tabs">
            <Tab label={<FormattedMessage id="shifts.generalInfo" />} {...a11yProps('info')} />
            <Tab label={<FormattedMessage id="shifts.notes" />} {...a11yProps('notes')} />
            <Tab label={<FormattedMessage id="shifts.billing" />} {...a11yProps('billing')} />
            <Tab label={<FormattedMessage id="shifts.historyLog" />} {...a11yProps('historyLog')} />
          </Tabs>
        </Box>
        <Flex mt={2} height="100%" overflow="auto" px={2}>
          <Box position="relative" width="100%">
            <AnimatedTabPanel value={tab} index={0}>
              <ComingSoonSpacer disableContent={!pageEnablingMap.generalInfo}>
                <GeneralInfo />
              </ComingSoonSpacer>
            </AnimatedTabPanel>
            <AnimatedTabPanel value={tab} index={1}>
              <ComingSoonSpacer disableContent={!pageEnablingMap.shiftNotes}>
                <ShiftNotes shiftId={currentShift?.id ?? ''} />
              </ComingSoonSpacer>
            </AnimatedTabPanel>
            <AnimatedTabPanel value={tab} index={2}>
              <ComingSoonSpacer disableContent={!pageEnablingMap.shiftsBilling}>
                <ShiftBilling shiftId={currentShift?.id ?? ''} />
              </ComingSoonSpacer>
            </AnimatedTabPanel>
            <AnimatedTabPanel value={tab} index={3}>
              <ComingSoonSpacer disableContent={!pageEnablingMap.shiftHistory}>
                <ShiftHistory shiftId={currentShift?.id ?? ''} />
              </ComingSoonSpacer>
            </AnimatedTabPanel>
          </Box>
        </Flex>
      </Flex>
    </Drawer>
  );
}

function a11yProps(name: string) {
  return {
    id: `shift-tab-${name}`,
    'aria-controls': `shift-tabpanel-${name}`,
  };
}
