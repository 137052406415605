import Toolbar from '@mui/material/Toolbar';
import AppBar, { AppBarProps } from '@mui/material/AppBar';
import { Box, Flex } from '@atoms/layout';
import { FaIcon } from '@atoms/icons';
import { ContainedIconButton } from '@atoms/buttons';
import { HeaderSelect } from '@molecules/headerSelect';
import { OnboardingProgress } from '@organisms/onboarding';
import { LicenseInfo } from '@organisms/licenseInfo';
import { ProfileMenu } from './profileMenu';
import { useOnboardingStore } from '@libs/store/onboarding';
import { useLocationStore } from '@libs/store/locations';
import { useEffect } from 'react';
import { Skeleton } from '@mui/material';

export const Header = ({ ...props }: AppBarProps) => {
  const { items, completedSteps } = useOnboardingStore();
  const completedStepsCount = Object.values(completedSteps).filter(Boolean).length;
  const onboardingCompleted = completedStepsCount >= items.length;
  const {
    locationsSimple,
    loadSimple,
    loadingSimple: locationsLoading,
    current: currentLocation,
    currentLocationID,
    setCurrentLocation,
  } = useLocationStore();

  const loading = locationsLoading;

  useEffect(() => {
    if (!locationsSimple.length) {
      loadSimple();
    }
  }, [locationsSimple, loadSimple]);

  useEffect(() => {
    if (!currentLocation) {
      setCurrentLocation(currentLocationID);
    }
  }, [currentLocation, currentLocationID, loadSimple]);

  return (
    <AppBar position="fixed" {...props}>
      <Toolbar>
        <Flex justifyContent="space-between" width="100%" alignCenter gap={3}>
          <Box width="220px">
            {loading ? (
              <Skeleton variant="rounded" animation="wave" height={48} width={220} />
            ) : (
              <HeaderSelect
                options={locationsSimple.map((loc) => ({
                  key: loc.id,
                  value: loc.id,
                  label: loc.name,
                }))}
                fullWidth
                value={currentLocationID ?? ''}
                onChange={(e) => setCurrentLocation(e.target.value)}
              />
            )}
          </Box>
          <Flex gap={3} alignCenter>
            {/* Size is hardcoded to be able to make `grow` animation */}
            {!onboardingCompleted && (
              <Box width="180px" height="40px">
                <OnboardingProgress />
              </Box>
            )}
            <LicenseInfo trialDaysLeft={14} />
            <Flex gap={1} alignCenter>
              <ContainedIconButton size="small" sx={{ height: '32px' }}>
                <FaIcon name="bell" />
              </ContainedIconButton>
              <ContainedIconButton size="small" sx={{ height: '32px' }}>
                <FaIcon name="circle-question" />
              </ContainedIconButton>
              <ProfileMenu />
            </Flex>
          </Flex>
        </Flex>
      </Toolbar>
    </AppBar>
  );
};
