import { Box } from '@framework/src/components/atoms/layout';
import { Fade } from '@mui/material';

interface TabPanelProps {
  children?: React.ReactNode;
  index: number;
  value: number;
}
export function AnimatedTabPanel(props: TabPanelProps) {
  const { children, value, index, ...other } = props;

  return (
    <Fade in={value === index} unmountOnExit>
      <Box
        role="tabpanel"
        id={`simple-tabpanel-${index}`}
        aria-labelledby={`simple-tab-${index}`}
        sx={{ height: '100%', width: '100%', top: 0, position: 'absolute' }}
        {...other}
      >
        {children}
      </Box>
    </Fade>
  );
}
