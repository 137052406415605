import { Box, Fade, IconButton, Stack, Typography, useTheme } from '@mui/material';
import { FormattedMessage, useIntl } from 'react-intl';
import { FilterMultiSelect, renderCheckboxOption } from '@atoms/inputs';
import { useShiftsFilter } from '@libs/store/shifts/shiftsFilter';
import { Button, MenuSplitButton } from '@atoms/buttons';
import { FaIcon } from '@atoms/icons';
import ArrowBackIosNewIcon from '@mui/icons-material/ArrowBackIosNew';
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
import { DateHelper } from '@bryntum/schedulerpro';
import { ActiveFilters } from '@molecules/dataDisplay';
import { ShiftsFilterMenu } from '@organisms/shifts/filterMenu';
import { FilterItem } from '@molecules/dataDisplay/activeFilters';
import { useOrgDepartmentsStore } from '@libs/store/settings/departments';
import { useEffect } from 'react';

type CalendarFilterBarProps = {
  loading?: boolean;
  displayedDate: Date;
  onToday: () => void;
  onWeekBack: () => void;
  onWeekForward: () => void;
  onNewShift: () => void;
  onNewSeries: () => void;
  onNewRotation: () => void;
  onListRefresh: () => void;
  onPublish: () => void;
  onShare: () => void;
};

export function CalendarFilterBar({
  loading = false,
  displayedDate,
  onToday,
  onWeekBack,
  onWeekForward,
  onNewShift,
  onNewSeries,
  onNewRotation,
  onListRefresh,
  onPublish,
  onShare,
}: CalendarFilterBarProps) {
  const theme = useTheme();
  const intl = useIntl();

  const { activeFilters, clearFilter, clearFilters, getFilter, setFilterFromOptions, setFilter } = useShiftsFilter();
  const { departments, departmentsTreeLoading, loadTree } = useOrgDepartmentsStore();

  useEffect(() => {
    if (!departments.length) {
      loadTree();
    }
  }, [loadTree, departments]);

  const statusFilterOptions = departments.map((department) => ({
    label: department.label,
    value: department.id,
  }));

  return (
    <Stack direction={'column'} spacing={1}>
      <Stack
        direction={'row'}
        spacing={2}
        sx={(theme) => ({
          width: '100%',
          height: '40px',
          paddingLeft: theme.spacing(1),
        })}
      >
        <Box
          sx={{
            paddingTop: '8px',
          }}
        >
          <FaIcon name="calendar-lines" fontSize={24} />
        </Box>
        <Typography variant="h5" sx={{ paddingTop: '6px' }}>
          {DateHelper.format(displayedDate, 'MMMM YYYY')}
        </Typography>
        <Button
          variant="contained"
          color="primary"
          style={{ backgroundColor: 'white', color: theme.palette.text.primary }}
          onClick={() => {
            onToday && onToday();
          }}
        >
          <FormattedMessage id="shifts.switchForTodayButton.label" />
        </Button>
        <IconButton
          aria-label="delete"
          size="small"
          onClick={() => {
            onWeekBack && onWeekBack();
          }}
        >
          <ArrowBackIosNewIcon fontSize="inherit" />
        </IconButton>
        <IconButton
          aria-label="delete"
          size="small"
          onClick={() => {
            onWeekForward && onWeekForward();
          }}
        >
          <ArrowForwardIosIcon fontSize="inherit" />
        </IconButton>
        <Stack
          direction={'row'}
          spacing={2}
          sx={(theme) => ({
            // height: '40px',
          })}
        >
          <FilterMultiSelect
            size="small"
            options={statusFilterOptions}
            inlineLabel
            label={`${intl.formatMessage({ id: 'filters.departments.label' })}:`}
            onApply={(v) => setFilterFromOptions('departments', v, statusFilterOptions)}
            onClear={() => clearFilter('departments')}
            value={getFilter<string[]>('departments')?.value ?? []}
            renderOption={renderCheckboxOption}
          />
          <ShiftsFilterMenu mode="calendar"/>
          <Fade in={activeFilters.length > 0} timeout={150}>
            <Button
              variant="passive"
              onClick={() => clearFilters()}
              startIcon={<FaIcon name="xmark" />}
              sx={{ ml: 'auto' }}
            >
              <FormattedMessage id="common.clearAllFilters" />
            </Button>
          </Fade>
        </Stack>
        <Box flex={1} />
        <Button
          variant="text"
          onClick={() => {
            onShare && onShare();
          }}
        >
          <FormattedMessage id="shifts.shareShiftButton.label" />
        </Button>
        <Button
          variant="outlined"
          onClick={() => {
            onPublish && onPublish();
          }}
        >
          <FormattedMessage id="shifts.publishShiftButton.label" />
        </Button>
        <MenuSplitButton
          onMainClick={() => {
            onNewShift && onNewShift();
          }}
          label={intl.formatMessage({ id: 'shifts.newShiftButton.label' })}
          menuItems={[
            {
              label: intl.formatMessage({ id: 'shifts.newShiftButton.menu.singleShift.label' }),
              onAction: () => {
                onNewShift && onNewShift();
              },
            },
            {
              label: intl.formatMessage({ id: 'shifts.newShiftButton.menu.newSeries.label' }),
              onAction: () => {
                onNewSeries && onNewSeries();
              },
            },
            {
              label: intl.formatMessage({ id: 'shifts.newShiftButton.menu.newRotation.label' }),
              onAction: () => {
                onNewRotation && onNewRotation();
              },
            },
          ]}
        />
      </Stack>
      <Box>
        <ActiveFilters
          filters={activeFilters.reduce((acc, f) => {
            if (f.key === 'locations') {
              return acc;
            }
            acc.push({
              ...f,
              label: <FormattedMessage id={`shifts.fields.${f.key}`} />,
            });
            return acc;
          }, [] as FilterItem[])}
          onDelete={(item) => {
            onListRefresh && onListRefresh();
            clearFilter(item.key);
          }}
        />
      </Box>
    </Stack>
  );
}
