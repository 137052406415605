import { createBrowserRouter, Navigate, Outlet, RouterProvider, useRouteError, useMatch, To } from 'react-router-dom';
import { Flex } from '@atoms/layout';
import { AuthGuard } from '@molecules/auth/authGuard';
import { ErrorMessage } from '@organisms/dataDisplay';
import { ComingSoonPage } from '@organisms/dataDisplay';
import { MainLayout } from '@templates/mainLayout';
import { Component as SchedulePage } from '@pages/schedule';
import { ChatwootWrapper } from '@shiftnex/framework/src/components/molecules/supportChat';
import { SupportChatUserContextProvider } from '@molecules/supportChat';
export function Routing() {
  return <RouterProvider router={router} />;
}

type RedirectProps = {
  match: string;
  to: To;
};
function Redirect({ match, to }: RedirectProps) {
  const hasMatch = useMatch(match);

  if (hasMatch) {
    // Redirect to default sub page
    return <Navigate to={to} replace={true} />;
  }
  return <Outlet />;
}

const router = createBrowserRouter([
  {
    path: '/login',
    async lazy() {
      const { SignInPage } = await import('@pages/auth');
      return { Component: SignInPage };
    },
  },
  {
    path: '/otp',
    async lazy() {
      const { OtpPage } = await import('@pages/auth');
      return { Component: OtpPage };
    },
  },
  {
    path: '/register',
    element: <Outlet />,
    children: [
      {
        path: '/register',
        async lazy() {
          const { AdminSignUp } = await import('@pages/auth');
          return { Component: AdminSignUp };
        },
      },
      {
        path: '/register/details',
        async lazy() {
          const { AdminSignUpDetails } = await import('@pages/auth');
          return { Component: AdminSignUpDetails };
        },
      },
      {
        path: '/register/address',
        async lazy() {
          const { AdminSignUpAddress } = await import('@pages/auth');
          return { Component: AdminSignUpAddress };
        },
      },
    ],
  },
  {
    path: '/',
    element: (
      <AuthGuard>
        <SupportChatUserContextProvider>
          <ChatwootWrapper>
            <MainLayout>
              <Outlet />
            </MainLayout>
          </ChatwootWrapper>
        </SupportChatUserContextProvider>
      </AuthGuard>
    ),
    // TODO: make 404 page
    ErrorBoundary: () => {
      const error = useRouteError();
      console.error('ERROR', error);
      console.log('ERROR', { err: error });
      return (
        <>
          <MainLayout>
            <ErrorMessage error={error as Error} />
          </MainLayout>
        </>
      );
    },
    children: [
      {
        path: '/',
        lazy: () => import('@pages/dashboard'),
      },
      {
        path: '/schedule',
        element: <Redirect match="/schedule" to="/schedule/calendar" />,
        children: [
          {
            path: '/schedule/calendar',
            element: <Redirect match="/schedule/calendar" to="/schedule/calendar/weekly-by-assignee" />,
            children: [
              {
                path: '/schedule/calendar/weekly-by-assignee',
                element: <SchedulePage viewType="calendarByAssignee" />,
              },
              {
                path: '/schedule/calendar/weekly-by-role',
                element: <SchedulePage viewType="calendarByRole" />,
              },
              {
                path: '/schedule/calendar/monthly-planning',
                element: <SchedulePage viewType="calendarMonthlyPlanning" />,
              },
            ],
          },
          {
            path: '/schedule/list',
            element: <Redirect match="/schedule/list" to="/schedule/list/all-shifts" />,
            children: [
              {
                path: '/schedule/list/all-shifts',
                element: <SchedulePage viewType="listAllShifts" />,
              },
              {
                path: '/schedule/list/series-n-rotations',
                element: <SchedulePage viewType="listSeriesNRotations" />,
              },
            ],
          },
          {
            path: '/schedule/templates',
            element: <Redirect match="/schedule/templates" to="/schedule/templates/shifts-series-n-rotations" />,
            children: [
              {
                path: '/schedule/templates/shifts-series-n-rotations',
                element: <SchedulePage viewType="templatesShiftsSeriesRotations" />,
              },
              {
                path: '/schedule/templates/shift-types',
                element: <SchedulePage viewType="templatesSeriesNRotations" />,
              },
            ],
          },
          {
            path: '/schedule/history-log',
            element: <SchedulePage viewType="historyLog" />,
          },
        ],
      },
      {
        path: '/providers',
        lazy: () => import('@pages/talents'),
      },
      {
        path: '/staffing-partners',
        lazy: () => import('@pages/staffingPartners'),
      },
      {
        path: '/reports',
        lazy: () => import('@pages/reports'),
      },
      {
        path: '/settings',
        async lazy() {
          const { SettingsRoot } = await import('@pages/settings');
          return { Component: SettingsRoot };
        },
        children: [
          {
            path: '/settings/general',
            element: <Navigate to="/settings/organization" replace={true} />,
          },
          {
            path: '/settings/organization',
            async lazy() {
              const { OrganizationSettings } = await import('@pages/settings');
              return { Component: OrganizationSettings };
            },
            children: [
              {
                path: '/settings/organization/details',
                async lazy() {
                  const { OrganizationDetails } = await import('@pages/settings');
                  return { Component: OrganizationDetails };
                },
              },
            ],
          },
          {
            path: '/settings/users',
            async lazy() {
              const { UsersPageDummy } = await import('@pages/settings');
              return { Component: UsersPageDummy };
            },
            children: [
              {
                path: '/settings/users/system-users',
                async lazy() {
                  const { UsersSettingsPage } = await import('@pages/settings');
                  return { Component: UsersSettingsPage };
                },
              },
            ],
          },
          {
            path: '/settings/qualifications-specialties',
            async lazy() {
              const { QualificationSpecialtiesDummy } = await import('@pages/settings');
              return { Component: QualificationSpecialtiesDummy };
            },
            children: [
              {
                path: '/settings/qualifications-specialties/qualifications',
                async lazy() {
                  const { Qualifications } = await import('@pages/settings');
                  return { Component: Qualifications };
                },
              },
              {
                path: '/settings/qualifications-specialties/specialties',
                async lazy() {
                  const { Specialties } = await import('@pages/settings');
                  return { Component: Specialties };
                },
              },
            ],
          },
          {
            path: '/settings/departments',
            async lazy() {
              const { Departments } = await import('@pages/settings');
              return { Component: Departments };
            },
          },
          {
            path: '/settings/locations',
            async lazy() {
              const { Locations } = await import('@pages/settings');
              return { Component: Locations };
            },
          },
          {
            path: '/settings/locations/:id',
            async lazy() {
              const { EditLocation } = await import('@pages/settings');
              return { Component: EditLocation };
            },
          },
          {
            path: '/settings/locations/new',
            async lazy() {
              const { NewLocation } = await import('@pages/settings');
              return { Component: NewLocation };
            },
          },
          {
            path: '/settings/*',
            element: (
              <Flex justifyCenter alignCenter height="100%" width="100%">
                <ComingSoonPage />
              </Flex>
            ),
          },
        ],
      },
    ],
  },
  {
    path: '/*',
    element: (
      <MainLayout>
        <ComingSoonPage />
      </MainLayout>
    ),
  },
]);
