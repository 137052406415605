import { ServerErrorData } from '@libs/utils/error';
import { request } from '../request';
import { filterToQueryParams } from '../utils/filterToQueryParams';
import { Filter } from '@libs/models/common';

export async function assignShift(shiftId: string, assigneeId: string) {
  const response = await request.post(`/v2/shifts/assign/${shiftId}`, {
    staffId: assigneeId,
  });
  return response;
}
export type AssignShiftError = Omit<ServerErrorData, 'title'> & {
  title:
    | 'StaffNotFound'
    | 'StaffDeactivated'
    | 'OverlapDetected'
    | 'ShiftNotFound'
    | 'ShiftRotationNotFound'
    | 'ShiftSeriesNotFound';
};

export async function unassignShift(shiftId: string, reason: string, reasonComment: string) {
  const response = await request.post(`/v2/shifts/unassign/${shiftId}`, {
    reason,
    reasonComment,
  });
  return response;
}

export async function getAssignments(startDate: string, endDate: string, filters: Filter[] = []) {
  const applyFilters = filterToQueryParams(
    filters.map((filter) => ({
      field: filter.key,
      value: filter.value,
    })),
  );
  const response = await request.get<{ staffId: string; shiftId: string }[]>('/v2/calendar/assignments', {
    params: {
      startDate: new Date(startDate).toISOString(),
      endDate: new Date(endDate).toISOString(),
    },
  });
  return response;
}
