import { FormattedMessage, useIntl } from 'react-intl';
import { useFormContext } from 'react-hook-form';
import Typography from '@mui/material/Typography';
import Chip from '@mui/material/Chip';
import { useTalentsStore } from '@libs/store/talents';
import { TalentListItem } from '@libs/models/talents';
import { FormAutocompleteMultiple, FormAutocompleteMultipleProps } from '@molecules/form/formAutocompleteMultiple';
import { Box, Flex } from '@atoms/layout';

export function AssigneesSelect({
  localizationSubset,
  name,
  selectedQualifications = [],
  ...props
}: Omit<FormAutocompleteMultipleProps<string[]>, 'options' | 'name'> & {
  localizationSubset: string;
  name: string;
  selectedQualifications?: string[];
}) {
  const intl = useIntl();
  const { watch } = useFormContext();
  const selected = watch(name);
  const selectedNumber = selected?.length ?? 0;
  const { talents } = useTalentsStore();
  const options = prepareOptions(talents, selectedQualifications);
  const talentsById = makeTalentsDictionary(talents);
  console.log('selectedQualifications', selectedQualifications);
  return (
    <FormAutocompleteMultiple
      options={options}
      groupBy={(option) => intl.formatMessage({ id: `talents.selector.stuffingType.${option.groupBy ?? ''}` })}
      label={<FormattedMessage id={`${localizationSubset}.talentConfiguration.assignees.label`} />}
      textFieldProps={{
        placeholder:
          selectedNumber === 0
            ? intl.formatMessage({
                id: `${localizationSubset}.talentConfiguration.assignees.placeholder`,
              })
            : undefined,
      }}
      disableCloseOnSelect
      name={name}
      // Trying to fit tags in one line
      limitTags={3}
      renderTags={(selected, getTagProps) =>
        selected.map((s, i) => (
          <Chip
            label={
              <Flex gap={0.5}>
                <Box>{s.label}</Box>
                <Box color="text.secondary">
                  {(talentsById[s.value].nurseQualifications || []).map((q) => q.abbreviation).join(', ')}
                </Box>
              </Flex>
            }
            title={`${s.label} - ${(talentsById[s.value].nurseQualifications || []).map((q) => q.abbreviation).join(', ')}`}
            {...getTagProps({ index: i })}
            key={s.value}
          />
        ))
      }
      renderOption={(props, option) => (
        <li {...props}>
          <Flex width="100%" justifyContent="space-between">
            <Flex>{option.label}</Flex>
            <Typography variant="caption" color="text.secondary">
              {(talentsById[option.value].nurseQualifications || []).map((q) => q.abbreviation).join(', ')}
            </Typography>
          </Flex>
        </li>
      )}
      {...props}
    />
  );
}

function prepareOptions(talents: TalentListItem[], selectedQualifications: string[]) {
  return (
    talents
      .filter((t) => {
        if (t.staffingStatus === 'Deactivated') {
          // Show only active talents
          return false;
        }

        // Show only talents with selected qualifications
        return t.nurseQualifications.some((q) => selectedQualifications.includes(q.id));
      })
      // Internal first, then by name
      .sort((a, b) => {
        if (a.staffingType === b.staffingType) {
          return [a.firstName, a.lastName].join(' ').localeCompare([b.firstName, b.lastName].join(' '));
        }
        if (a.staffingType === 'Internal') {
          return -1;
        } else if (b.staffingType === 'Internal') {
          return 1;
        }
        return a.staffingType.localeCompare(b.staffingType);
      })
      .map((talent) => {
        return {
          key: talent.id,
          staffingType: talent.staffingType,
          // Group by staffing type
          groupBy: talent.staffingType,
          label: [talent.firstName, talent.lastName].filter(Boolean).join(' '),
          value: talent.id,
        };
      })
  );
}

function makeTalentsDictionary(talents: TalentListItem[]) {
  return talents.reduce<Record<string, TalentListItem>>((acc, item) => {
    acc[item.id] = item;
    return acc;
  }, {});
}
