import { StaffingPartnerList } from '@libs/models/staffingPartners';

export const productionMock: StaffingPartnerList[] = [
  {
    id: '1',
    icon: '',
    name: 'Actriv',
    region: 'NY, Albany',
    invitationStatus: 'Active',
    relationType: 'Direct',
    contact: {
      email: 'john.doe@example.com',
      name: 'John Doe',
      phoneNumber: '+380676055041',
      title: 'Staffing coordinator',
      secondaryPhoneNumber: '',
    },
  },
  {
    id: '2',
    icon: '',
    name: 'Zap Nurse Agency',
    region: 'NY, Albany',
    invitationStatus: 'Active',
    relationType: 'Direct',
    contact: {
      email: 'john.doe@example.com',
      name: 'John Doe',
      phoneNumber: '+380676055041',
      title: 'Staffing coordinator',
      secondaryPhoneNumber: '',
    },
  },
];
