import { useShiftsFilter } from '@libs/store/shifts';
import { FilterItem, FilterMenuButton } from '@molecules/form/filterMenu';
import { useQualificationsFilterMenu } from './useQualificationsFilterMenu';
import { useProfessionalsFilterMenu } from './useProfessionalsFilterMenu';
import { useSharedWithinFilterMenu } from './useSharedWithinFilterMenu';
import { useDateRangeFilterMenu } from './useDateRangeFilterMenu';
import { useLocationsFilterMenu } from './useLocationsFilterMenu';
import { useShiftTypeFilterMenu } from './useShiftTypeFilterMenu';
import { useSpecialtyFilterMenu } from './useSpecialtyFilterMenu';
import { useStatusFilterMenu } from './useStatusFilterMenu';

type ShiftsFilterMenuProps ={
  mode?: 'list' | 'calendar';
}
export function ShiftsFilterMenu({mode='list'}:ShiftsFilterMenuProps) {
  const filterItem = [
    // useSharedWithinFilterMenu(),
    // useShiftTypeFilterMenu(),
    // useLocationsFilterMenu(),
    useQualificationsFilterMenu(),
    useSpecialtyFilterMenu(),
    useStatusFilterMenu(),
    useProfessionalsFilterMenu(),
  ];
  if(mode==='list'){
    filterItem.unshift(useDateRangeFilterMenu())
  }
  const { setFilter } = useShiftsFilter();

  return (
    <FilterMenuButton
      items={filterItem}
      onFilterApplied={(key, value, label) => {
        setFilter({
          key,
          value,
          valueView: label,
        });
      }}
    />
  );
}

function useFilterItems(): FilterItem[] {
  return [
    useDateRangeFilterMenu(),
    // useSharedWithinFilterMenu(),
    // useShiftTypeFilterMenu(),
    // useLocationsFilterMenu(),
    useQualificationsFilterMenu(),
    useSpecialtyFilterMenu(),
    useStatusFilterMenu(),
    useProfessionalsFilterMenu(),
  ];
}
