import { useEffect } from 'react';
import { useTalentsStore } from '@libs/store/talents';
import { ShiftDetailsDrawer, ShiftsFilters, ShiftTableMenu } from '@organisms/shifts';
import { Box, Flex, PrimaryPaper } from '@atoms/layout';
import { SeriesNRotationsTable } from '@organisms/dataDisplay/seriesNRotationsTable';
import { useShiftSeriesAddEditStore } from '@libs/store/shifts/shiftSeriesAddEditStore';
import { useShiftRotationAddEditStore } from '@libs/store/shifts/shiftRotationAddEditStore';
import { useShiftInProgressStore } from '@libs/store/shifts/shiftInProgressStore';
import { useShiftAddEditStore } from '@libs/store/shifts';
import { EditShiftDrawer } from '../calendar/drawers/editShift';
import { ShiftInProgressDrawer } from '../calendar/drawers/shiftInProgress';
import { EditSeriesDrawer } from '../calendar/drawers/editSeries';
import { EditRotationDrawer } from '../calendar/drawers/editRotation';
import { useShareDrawerStore } from '@libs/store/shifts/shiftShareStore';
import { ShareShifts } from '../calendar/drawers/shareShifts';

export function RotationsListPage() {
  const { drawerOpen: seriesDrawerOpen } = useShiftSeriesAddEditStore();
  const { drawerOpen: rotationDrawerOpen } = useShiftRotationAddEditStore();
  const { drawerOpen: inProgressDrawerOpen } = useShiftInProgressStore();
  const { drawerOpen: shiftEditDrawerOpen } = useShiftAddEditStore();
  const { drawerOpen: shareDrawerOpen } = useShareDrawerStore();
  const { load } = useTalentsStore();

  const loading = false;

  useEffect(() => {
    // eslint-disable-next-line @typescript-eslint/no-floating-promises
    load({});
  }, [load]);

  return (
    <>
      <ShiftTableMenu />
      <PrimaryPaper sx={{ height: '100%', minHeight: 0, mt: 2 }}>
        <Flex column height="100%">
          <ShiftsFilters loading={loading} />
          <Box mt={2} height="100%" minHeight={0}>
            <SeriesNRotationsTable />
          </Box>
        </Flex>
      </PrimaryPaper>
      <ShiftDetailsDrawer />
      {shiftEditDrawerOpen && (
        <EditShiftDrawer
          onSaveCall={() => {
            // refreshLists();
          }}
        />
      )}
      {inProgressDrawerOpen && <ShiftInProgressDrawer />}
      {seriesDrawerOpen && <EditSeriesDrawer />}
      {rotationDrawerOpen && <EditRotationDrawer />}
      {shareDrawerOpen && <ShareShifts/>}
    </>
  );
}
