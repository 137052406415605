import { useState } from 'react';
import { FormattedMessage } from 'react-intl';
import { useTheme } from '@mui/material/styles';
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';
import Fade from '@mui/material/Fade';
import { useShiftsAssignment, useShiftsListStore } from '@libs/store/shifts';
import { useCurrentAgency } from '@libs/store/agency';
import { useNotification } from '@libs/snackbar';
import { isServerError } from '@libs/utils/error';
import { parseError } from '@libs/api/errors';
import { ShiftOld } from '@libs/models/shifts/shift';
import { AssignDialog, UnassignDialog } from '@organisms/shifts';
import { LoadingButton } from '@atoms/buttons';
import { Box, Flex } from '@atoms/layout';
import { Avatar } from '@atoms/dataDisplay';
import { FaIcon } from '@atoms/icons';

type AssigneeButton = {
  shift: ShiftOld;
  fullWidth?: boolean;
};
export function ShiftAssigneeButton({ shift, fullWidth }: AssigneeButton) {
  const theme = useTheme();
  const value = shift.assignee;
  const { assignShift, unassignShift, loadingById } = useShiftsAssignment();
  const { reloadCurrentFilter } = useShiftsListStore();
  const currentAgencyState = useCurrentAgency();
  const { showSuccessIntl, showError, showErrorIntl } = useNotification();
  const shiftId = shift.id;
  const [opedDialog, setOpedDialog] = useState(false);
  const [opedAssignDialog, setOpedAssignDialog] = useState(false);
  const loading = loadingById[shiftId] ?? false;
  const assign = async (userId: string) => {
    if (loading) return;
    await assignShift(shiftId, userId)
      .then(() => {
        reloadCurrentFilter();
        showSuccessIntl('shifts.assignSuccessful');
        setOpedAssignDialog(false);
      })
      .catch((error: unknown) => {
        if (isServerError(error) && !error.response?.data.errors) {
          showErrorIntl(`shifts.assignError.${error.response?.data.title}`);
          return;
        }
        showError(parseError(error).message);
      });
  };
  const unassign = async (reason: string, reasonComment: string) => {
    if (loading) return;
    await unassignShift(shiftId, { reason, reasonComment })
      .then(() => {
        reloadCurrentFilter();
        setOpedDialog(false);
        showSuccessIntl('shifts.unassignSuccessful');
      })
      .catch((error: unknown) => showError(parseError(error).message));
  };
  const openUnassign = (event?: React.MouseEvent<HTMLButtonElement>) => {
    event?.stopPropagation();
    setOpedDialog(true);
  };

  if (!value) {
    return (
      <>
        <LoadingButton
          id="assign"
          onClick={(event) => {
            event.stopPropagation();
            setOpedAssignDialog(true);
          }}
          loading={loading}
          rounded
          fullWidth={fullWidth}
        >
          <Fade in={!loading} timeout={150}>
            <Flex alignCenter>
              <Box fontSize={16} mr={1} ml={1}>
                <FaIcon name="user-magnifying-glass" color={theme.palette.primary.main} />
              </Box>
              <FormattedMessage id="shifts.assignBtnLabel" />
            </Flex>
          </Fade>
        </LoadingButton>

        <AssignDialog
          open={opedAssignDialog}
          shift={shift}
          onClose={() => setOpedAssignDialog(false)}
          onSubmit={assign}
          loading={loading}
        />
      </>
    );
  }

  return (
    <>
      <Flex justifyContent="space-between" alignCenter height="100%">
        <Fade in={!loading} timeout={150}>
          <Flex alignCenter gap={1.75} height="100%" width="100%">
            <Avatar
              sx={{
                width: 32,
                height: 32,
              }}
              src={value.avatar}
              alt={value.name}
            />
            <Flex column width="100%" minWidth="0" textAlign="left">
              <Typography variant="subtitle2" noWrap>
                {value.name}
              </Typography>
              <Typography variant="caption" noWrap color="text.secondary">
                {value.assignedByClient ? 'Assigned by client' : ''}
              </Typography>
            </Flex>
          </Flex>
        </Fade>

        <Fade in={!loading} timeout={150}>
          <IconButton sx={{ width: '40px' }} onClick={openUnassign}>
            <FaIcon name="xmark" variant="light" />
          </IconButton>
        </Fade>
      </Flex>

      <UnassignDialog
        open={opedDialog}
        shift={shift}
        onClose={() => setOpedDialog(false)}
        onSubmit={unassign}
        loading={loading}
      />
    </>
  );
}
