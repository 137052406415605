import { StaffingPartnerList } from '@libs/models/staffingPartners';
import { developMock } from './development';
import { uatMock } from './uat';
import { productionMock } from './production';
import { ENV_MODE } from '@libs/config';

const EnvMap: Record<string, StaffingPartnerList[]> = {
  uat: uatMock,
  development: developMock,
  production: productionMock,
};

export function getFetchMock() {
  return EnvMap[ENV_MODE];
}
