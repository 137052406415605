import dayjs from 'dayjs';
import { useShiftsAssignment } from '@libs/store/shifts';
import { useNotification } from '@libs/snackbar';
import { parseError } from '@libs/api/errors';
import { UnassignDialog } from '@organisms/shifts';

type CalendarUnassignDialogProps = {
  onAction?: () => void;
};
export function CalendarUnassignDialog({ onAction }: CalendarUnassignDialogProps) {
  const { unassignShift, closeShiftUnassignDialog, currentShift, unassignDialogOpen } = useShiftsAssignment();
  const { showSuccessIntl, showError } = useNotification();

  const unassign = async (reason: string, reasonComment: string) => {
    if (!currentShift) return;
    await unassignShift(currentShift.id, { reason, reasonComment })
      .then(() => {
        onAction && onAction();
        closeShiftUnassignDialog();
        showSuccessIntl('shifts.unassignSuccessful');
      })
      .catch((error: unknown) => showError(parseError(error).message));
  };
  return (
    <>
      {currentShift && (
        <UnassignDialog
          open={unassignDialogOpen}
          shift={{
            shiftStart: dayjs(currentShift?.startDate).toDate(),
            shiftLength: dayjs(currentShift?.endDate).diff(dayjs(currentShift?.startDate)),
            qualifications: currentShift?.staffNurseQualificationFilters || [],
            assignee: {
              name: `${currentShift?.staff.firstName} ${currentShift?.staff.lastName}`,
              assignedByClient: false,
              avatar: '',
            },
            id: currentShift?.id || '',
            recurrence: 'single',
            shiftEnd: dayjs(currentShift?.endDate).toDate(),
            specialty: {
              category: '',
              directQualifications: [],
              id: '',
              localizationKey: '',
              name: '',
              otherQualifications: [],
            },
            timeZone: '+00:00',
            status: 'pending',
          }}
          onClose={() => closeShiftUnassignDialog()}
          onSubmit={unassign}
          loading={false}
        />
      )}
    </>
  );
}
