import { ShiftSeriesUpdate } from '@libs/models/shifts/series';
import { request } from '../request';

export async function createShiftSeries(data: ShiftSeriesUpdate) {
  const response = request.post<void>('/v2/shifts/series', {
    ...data,
    specialityId: data.specialityId || null,
    subDepartmentNodeId: data.subDepartmentNodeId || null,
    departmentNodeId: data.departmentNodeId || null,
    tags: data.tags.reduce((acc, tag) => {
      if (tag) {
        acc.push(tag + '');
      }
      return acc;
    }, [] as string[]),
  });
  return response;
}
