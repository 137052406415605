import { useState } from 'react';
import { useIntl } from 'react-intl';
import { Grid, Stack, Tab } from '@mui/material';
import TabContext from '@mui/lab/TabContext';
import TabPanel from '@mui/lab/TabPanel';
import TabList from '@mui/lab/TabList';
import { DateTimeSlots } from '@libs/models/shifts/rotation';
import { Box } from '@atoms/layout';
import { ShiftsCalendar } from './shiftsCalendar';

type RotationScheduleSectionProps = {
  localizationSubset: string;
  onChange: (sequence: DateTimeSlots[]) => void;
  slots: DateTimeSlots[];
};

export function RotationScheduleSection({ localizationSubset, onChange, slots }: RotationScheduleSectionProps) {
  const intl = useIntl();
  const [tabNumber, setTab] = useState('1');

  const handleTabChange = (event: React.SyntheticEvent, newValue: string) => {
    setTab(newValue);
  };

  return (
    <Box sx={{ width: '100%', typography: 'body1' }}>
      <TabContext value={tabNumber}>
        <Box sx={{ width: '100%' }}>
          <TabList onChange={handleTabChange} aria-label="lab API tabs example" sx={{ width: '100%' }}>
            <Tab
              label={intl.formatMessage({
                id: `${localizationSubset}.rotationSchedulePreview.tabs.calendarView.label`,
              })}
              value="1"
              sx={{ width: '50%' }}
            />
            <Tab
              label={intl.formatMessage({
                id: `${localizationSubset}.rotationSchedulePreview.tabs.weeklyView.label`,
              })}
              value="2"
              sx={{ width: '50%' }}
              disabled
            />
          </TabList>
        </Box>
        <TabPanel value="1" sx={{ padding: 0 }}>
          <Grid container>
            <Grid item xs={6}>
              <ShiftsCalendar slots={slots} />
            </Grid>
            <Grid item xs={6}>
              <Stack direction={'column'}></Stack>
            </Grid>
          </Grid>
        </TabPanel>
        <TabPanel value="2" sx={{ padding: 0 }}>
          Weekly view here
        </TabPanel>
      </TabContext>
    </Box>
  );
}
