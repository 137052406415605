import { create } from 'zustand';
import { DateRangeShortcutsItem } from '@libs/models/common';
import { dateRangeShortcuts } from '@libs/models/shifts';
import { createDefaultFilterSlice, DefaultFilterState } from '../defaultFilterSlice';
import { persist } from 'zustand/middleware';

type ShiftFilterState = {
  dateRangeShortcuts: DateRangeShortcutsItem[];
};
export const useShiftsFilter = create<DefaultFilterState & ShiftFilterState>()(
  persist(
    (...a) => ({
      ...createDefaultFilterSlice(...a),
      dateRangeShortcuts: dateRangeShortcuts,
    }),
    {
      name: 'shiftsList',
    },
  ),
);
