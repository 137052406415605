import { FormattedMessage, useIntl } from 'react-intl';
import { Button, MenuSplitButton, ToggleButton, ToggleButtonGroup } from '@atoms/buttons';
// import { DuotoneIcon, FaIcon } from '@atoms/icons';
import { Box, Flex } from '@atoms/layout';
import { useNavigate } from 'react-router-dom';
import { useShiftAddEditStore } from '@libs/store/shifts';
import { useShiftInProgressStore } from '@libs/store/shifts/shiftInProgressStore';
import { useShiftRotationAddEditStore } from '@libs/store/shifts/shiftRotationAddEditStore';
import { useShiftSeriesAddEditStore } from '@libs/store/shifts/shiftSeriesAddEditStore';
import { useShareDrawerStore } from '@libs/store/shifts/shiftShareStore';

export function ShiftTableMenu() {
  const navigate = useNavigate();
  const intl = useIntl();
  const { openShiftSeriesDrawer } = useShiftSeriesAddEditStore();
  const { openShiftRotationDrawer } = useShiftRotationAddEditStore();
  const { openInProgressDrawer } = useShiftInProgressStore();
  const { openShiftShareDrawer } = useShareDrawerStore();
  const { openShiftDrawer } = useShiftAddEditStore();
  const currentPath = location.pathname;
  const paths = {
    series: '/schedule/list/series-n-rotations',
    shifts: '/schedule/list/all-shifts',
  };
  const locationListMapping: Record<string, string> = {
    [paths.series]: 'series',
    [paths.shifts]: 'shifts',
  };
  const handlePublishShift = () => {};
  const handleNewShift = () => {
    openShiftDrawer();
  };
  const handleCreateRotation = () => {
    openShiftRotationDrawer();
  };
  const handleCreateSeries = () => {
    openShiftSeriesDrawer();
  };

  const handleShare = ()=>{
    openShiftShareDrawer();
  }
  const handlePublish = ()=>{}

  return (
    <>
      <Flex gap={2}>
        <ToggleButtonGroup aria-label="shift type switcher" value={locationListMapping[currentPath]}>
          <ToggleButton value="shifts" size="large" onClick={() => navigate(paths.shifts)}>
            <FormattedMessage id="shifts.type.shifts" />
          </ToggleButton>
          <ToggleButton value="series" size="large" onClick={() => navigate(paths.series)}>
            <FormattedMessage id="shifts.type.series" />
          </ToggleButton>
        </ToggleButtonGroup>
        {/* <ToggleButtonGroup value="list" aria-label="view type switcher">
          <ToggleButton value="list" aria-label="list" size="large">
            <Flex mx={0.5}>
              <DuotoneIcon name="list" active />
            </Flex>
          </ToggleButton>
          <ToggleButton value="calendar" aria-label="calendar" size="large">
            <Flex mx={0.5}>
              <FaIcon name="calendar" />
            </Flex>
          </ToggleButton>
        </ToggleButtonGroup> */}
        <Box flex={1} />
        <Button
          variant="text"
          onClick={handleShare}
        >
          <FormattedMessage id="shifts.shareShiftButton.label" />
        </Button>
        <Button
          variant="outlined"
          onClick={handlePublish}
        >
          <FormattedMessage id="shifts.publishShiftButton.label" />
        </Button>
        <MenuSplitButton
          onMainClick={handleNewShift}
          label={intl.formatMessage({ id: 'shifts.newShiftButton.label' })}
          menuItems={[
            {
              label: intl.formatMessage({ id: 'shifts.newShiftButton.menu.singleShift.label' }),
              onAction: handleNewShift,
            },
            {
              label: intl.formatMessage({ id: 'shifts.newShiftButton.menu.newSeries.label' }),
              onAction: handleCreateSeries,
            },
            {
              label: intl.formatMessage({ id: 'shifts.newShiftButton.menu.newRotation.label' }),
              onAction: handleCreateRotation,
            },
          ]}
        />
      </Flex>
    </>
  );
}
