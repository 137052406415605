import { PropsWithChildren } from 'react';
import Typography from '@mui/material/Typography';
import { PrimaryPaper } from '@atoms/layout';
import { Accordion, AccordionDetails, AccordionSummary, Box, Stack } from '@mui/material';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';

type FormBlockProps = {
  title?: React.ReactNode;
};
export function FormBlock({ children, title }: PropsWithChildren<FormBlockProps>) {
  return (
    <>
      <SubFormTitle>{title}</SubFormTitle>
      <PrimaryPaper>{children}</PrimaryPaper>
    </>
  );
}
function SubFormTitle({ children }: { children: React.ReactNode }) {
  return (
    <Typography variant="subtitle1" sx={{ mt: 2, mb: 1 }}>
      {children}
    </Typography>
  );
}

export function CollapsibleFormBlock({
  children,
  sectTitle,
  sectTitleBadges,
  expanded = false,
  forceExpanded,
}: {
  children: React.ReactNode | React.ReactNode[] | JSX.Element | JSX.Element[];
  sectTitle: string | React.ReactNode;
  sectTitleBadges?: React.ReactNode | React.ReactNode[] | JSX.Element | JSX.Element[] | null;
  expanded?: boolean;
  forceExpanded?: boolean;
}) {
  return (
    <PrimaryPaper sx={{ mt: 1, paddingTop: 0, paddingBottom: 0 }}>
      <Accordion sx={{ background: 'transparent' }} defaultExpanded={expanded} expanded={forceExpanded}>
        <AccordionSummary expandIcon={<ExpandMoreIcon />} aria-controls="panel1-content" id="panel1-header">
          <Stack direction={'row'} sx={{ width: 'calc(100% - 8px)' }}>
            {sectTitle}
            <Box flex={1} />
            {sectTitleBadges}
          </Stack>
        </AccordionSummary>
        <AccordionDetails>{children}</AccordionDetails>
      </Accordion>
    </PrimaryPaper>
  );
}
