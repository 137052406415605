import dayjs, { Dayjs } from 'dayjs';
import { DateCalendar } from '@mui/x-date-pickers/DateCalendar';
import { PickersDay, PickersDayProps } from '@mui/x-date-pickers/PickersDay';
import { DateTimeSlots } from '@libs/models/shifts/rotation';
import { useState } from 'react';

export function ShiftsCalendar({ slots }: { slots: DateTimeSlots[] }) {
  const [referenceDate, setReferenceDate] = useState(dayjs(new Date()));
  const highlightedDays = calcHighlightedDays(referenceDate.month(), slots);

  return (
    <DateCalendar
      referenceDate={referenceDate}
      slots={{
        day: ServerDay,
      }}
      slotProps={{
        day: {
          // @ts-expect-error There is no way to tell TS that this is a valid prop
          highlightedDays,
        },
      }}
      onMonthChange={(date) => setReferenceDate(date)}
    />
  );
}

function ServerDay(props: PickersDayProps<Dayjs> & { highlightedDays?: number[] }) {
  const { highlightedDays = [], day, outsideCurrentMonth, ...other } = props;

  const isSelected = !props.outsideCurrentMonth && highlightedDays.indexOf(props.day.date()) >= 0;

  return (
    <PickersDay
      {...other}
      outsideCurrentMonth={outsideCurrentMonth}
      day={day}
      sx={(theme) => (isSelected ? { borderRadius: '50%', border: `1px solid ${theme.palette.primary.main}` } : {})}
    />
  );
}

function calcHighlightedDays(currentVewMonth: number, slots: DateTimeSlots[] = []) {
  return slots.filter((s) => s.start.month() === currentVewMonth).map((s) => s.start.date());
}
