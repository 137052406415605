import { request } from '../request';
import { Shift, SingleShift, ShiftList } from '@libs/models/shifts/shift';
import { filterToQueryParams } from '../utils/filterToQueryParams';
import { Filter } from '@libs/models/common';

export function createSingleShift(data: SingleShiftEditData) {
  return request.post<void>('/v2/shifts/single', {
    ...data,
    specialityId: data.specialty || null,
    subDepartmentNodeId: data.subDepartmentNodeId || null,
  });
}

type SingleShiftEditData = Omit<SingleShift, 'startDate'> & {
  startDate: string;
  specialty?: string;
};

export async function updateSingleShift(shiftId: string, data: SingleShift) {
  return request.patch<void>(`/v2/shifts/${shiftId}`, {
    staffingType: data.staffingType,
    date: {
      startDate: data.startDate,
      durationInSeconds: data.durationInSeconds,
      timeZone: data.timeZone,
    },
    staffNurseQualificationFilterIds: data.staffNurseQualificationFilterIds,
    speciality: {
      isEmpty: !data.specialityId,
      id: data.specialityId || null,
    },
    subDepartment: {
      isEmpty: !data.subDepartmentNodeId,
      id: data.subDepartmentNodeId || null,
    },
    assignee: {
      isEmpty: !data.assignees.length,
      id: data.assignees.length ? data.assignees[0] : null,
    },
    departmentNodeId: data.departmentNodeId,
    locationId: data.locationId,
    tags: data.tags.reduce((acc, elem) => {
      if (elem) {
        acc.push(elem);
      }
      return acc;
    }, [] as string[]),
    note: data.note,
    isUrgent: data.isUrgent,
  });
}

export async function getSingleShift(shiftId: string): Promise<Shift> {
  const resp = await request.get(`/v2/shifts/${shiftId}`);

  return {
    ...resp.data,
    internalId: resp.data.externalId,
  };
}

export function cancelSingleShift(shiftId: string) {
  return request.post<void>(`/v2/shifts/cancel/${shiftId}`);
}

export function deleteSingleShift(shiftId: string) {
  return request.delete<void>(`/v2/shifts/${shiftId}`);
}

export function shareShifts(shiftIds: string[], agenciesIds: string[]) {
  // return request.post<void>(`/v2/shifts/share`,{
  // });
}

export async function publishShifts(shiftIds: string[]) {
  return request.post<void>(`/v2/shifts/publish`, shiftIds);
}

const staffingTypesToBEMap: Record<string, string> = {
  All: '1',
  Internal: '2',
  External: '3',
};

export async function getEvents(startDate: string, endDate: string, filters: Filter[] = []) {
  const dataMock: ShiftList[] = Object.values(EventsMocked);
  const applyFilters = filterToQueryParams(
    filters.map((filter) => ({
      field: filter.key === 'specialties' ? 'specialities' : filter.key,
      value:
        filter.key === 'staffingtypes'
          ? (filter.value as string[]).map((val) => staffingTypesToBEMap[val])
          : filter.value,
    })),
  );

  const response = await request.get<ShiftList[]>('/v3/calendar/events', {
    params: {
      startDate: new Date(startDate).toISOString(),
      endDate: new Date(endDate).toISOString(),
      ...applyFilters,
    },
  });
  response.data = response.data.map((evt: any) => ({
    ...evt,
    recurrence: evt.recurrence || 'single',
    nurseQualifications: evt.staffNurseQualificationFilters,
    otherQualifications: evt.staffOtherQualificationFilters,
  }));
  return response;
  // const fake = async () =>
  //   new Promise<{ data: ShiftList[]; total: number }>((resolve) => {
  //     setTimeout(() => {
  //       resolve({ data: dataMock, total: dataMock.length });
  //     }, 1000);
  //   });

  // return await fake();
}

const EventsMocked: ShiftList[] = [
  {
    id: '1',
    startDate: '2024-12-10T03:00:00.000Z',
    endDate: '2024-12-10T04:00:00.000Z',
    nurseQualifications: [
      {
        id: 'caregiver',
        abbreviation: 'Caregiver',
      },
    ],
    otherQualifications: [],
    staff: {
      id: '',
    },
    status: 'Pending',
    timeZone: '',
    isUrgent: true,
    recurrence: 'single',
  },
  {
    id: '2',
    startDate: '2024-12-10T04:00:00.000Z',
    endDate: '2024-12-10T06:00:00.000Z',
    nurseQualifications: [
      {
        id: 'qualification-01jbhf5sdp05mqajjdrzhczt6r',
        abbreviation: 'CNA',
      },
      {
        id: 'caregiver',
        abbreviation: 'Caregiver',
      },
    ],
    otherQualifications: [],
    staff: {
      id: '',
    },
    status: 'Opened',
    timeZone: '',
    isUrgent: true,
    recurrence: 'single',
  },
  {
    id: '3',
    startDate: '2024-12-10T05:00:00.000Z',
    endDate: '2024-12-10T08:00:00.000Z',
    nurseQualifications: [
      {
        id: 'lpn',
        abbreviation: 'LPN',
      },
    ],
    otherQualifications: [],
    staff: {
      id: '',
    },
    status: 'Opened',
    timeZone: '',
    isUrgent: true,
    recurrence: 'single',
  },
  {
    id: '4',
    startDate: '2024-12-10T08:00:00.000Z',
    endDate: '2024-12-10T012:00:00.000Z',
    nurseQualifications: [
      {
        id: 'qualification-01jbhf5sdp05mqajjdrzhczt6r',
        abbreviation: 'CNA',
      },
    ],
    otherQualifications: [],
    staff: {
      id: '',
    },
    status: 'Opened',
    timeZone: '',
    isUrgent: true,
    recurrence: 'single',
  },
  {
    id: '5',
    startDate: '2024-12-10T09:00:00.000Z',
    endDate: '2024-12-10T013:00:00.000Z',
    nurseQualifications: [
      {
        id: 'qualification-01jbhf5sdp05mqajjdrzhczt6r',
        abbreviation: 'CNA',
      },
    ],
    otherQualifications: [],
    staff: {
      id: '',
    },
    status: 'Opened',
    timeZone: '',
    isUrgent: true,
    recurrence: 'single',
  },
  {
    id: '6',
    startDate: '2024-12-10T14:00:00.000Z',
    endDate: '2024-12-10T018:00:00.000Z',
    nurseQualifications: [
      {
        id: 'caregiver',
        abbreviation: 'Caregiver',
      },
    ],
    otherQualifications: [],
    staff: {
      id: '',
    },
    status: 'Opened',
    timeZone: '',
    isUrgent: true,
    recurrence: 'single',
  },
  {
    id: '7',
    startDate: '2024-12-11T20:00:00.000Z',
    endDate: '2024-12-11T23:00:00.000Z',
    nurseQualifications: [
      {
        id: 'caregiver',
        abbreviation: 'Caregiver',
      },
    ],
    otherQualifications: [],
    staff: {
      id: '',
    },
    status: 'Opened',
    timeZone: '',
    isUrgent: true,
    recurrence: 'single',
  },
  {
    id: '8',
    startDate: '2024-12-11T22:00:00.000Z',
    endDate: '2024-13-11T04:00:00.000Z',
    nurseQualifications: [
      {
        id: 'caregiver',
        abbreviation: 'Caregiver',
      },
    ],
    otherQualifications: [],
    staff: {
      id: '',
    },
    status: 'Opened',
    timeZone: '',
    isUrgent: true,
    recurrence: 'single',
  },
];
