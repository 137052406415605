import { getLocationsSimple, getOrgLocation, getOrgLocations } from '@libs/api/settings';
import { LocationExtra, LocationShort } from '@libs/models/locations';
import { create } from 'zustand';
import { getLocations } from '@libs/api/locations';
import { LocationList } from '@libs/models/locations';

type State = {
  loading: boolean;
  locations: LocationList[];

  loadingSimple: boolean;
  locationsSimple: LocationShort[];

  currentLocationLoading: boolean;
  currentLocationID: string;
  current: LocationExtra | null;
  loadLocations: () => void;
  loadSimple: () => void;
  setCurrentLocation: (id: string) => void;
};

const LS_CURRENT_LOCATION_KEY = 'locationSelection';

export const useLocationStore = create<State>()((set, get) => ({
  loading: false,
  locations: [],

  loadingSimple: false,
  locationsSimple: [],

  currentLocationLoading: false,
  current: null,
  currentLocationID: '',

  loadSimple: async () => {
    set({ loadingSimple: true });
    try {
      const resp = await getLocationsSimple();
      const currentLocID = localStorage.getItem(LS_CURRENT_LOCATION_KEY);
      set({
        loadingSimple: false,
        locationsSimple: resp.data,
        currentLocationID: currentLocID ?? resp.data[0].id,
      });
    } finally {
      set({ loadingSimple: false });
    }
  },

  loadLocations: async () => {
    try {
      set({ loading: true });
      const { data } = await getLocations();

      set({ locations: data.data });
    } finally {
      set({ loading: false });
    }
  },

  setCurrentLocation: async (id) => {
    if (!id) {
      return;
    }
    set({ loading: true });
    localStorage.setItem(LS_CURRENT_LOCATION_KEY, id);
    try {
      const resp = await getOrgLocation(id);
      set({
        loading: false,
        current: resp,
        currentLocationID: resp.id,
      });
    } finally {
      set({ loading: false });
    }
  },
}));
