import { Rotation } from '@libs/models/shifts/shift';
import { filterToQueryParams } from '../utils/filterToQueryParams';
import { request } from '../request';
import { Filter } from '@libs/models/common';

type FetchShiftsListResponseData = {
  hasNextPage: boolean;
  hasPreviousPage: boolean;
  pageNumber: number;
  pageSize: number;
  totalCount: number;
  items: any[];
};

export async function fetchRotationsList(page = 0, pageSize = 10, filters: Filter[] = []) {
  const applyFilters =
    filters && filters.length
      ? filterToQueryParams(filters.map((filter) => ({ field: filter.key, value: filter.value })))
      : {};
  const response = await request.get<FetchShiftsListResponseData>('/v2/shifts/series-and-rotations/list-view', {
    params: {
      pageNumber: page,
      pageSize: pageSize,
      search: '',
      ...applyFilters,
    },
  });

  return {
    items: response.data.items.map(
      (item) =>
        ({
          id: item.id,
          shiftStart: new Date(item.startDate),
          shiftEnd: new Date(item.endDate),
          shiftLength: new Date(item.endDate).getTime() - new Date(item.startDate).getTime(),
          recurrence: item.id.match(/^\w+/)[0] === 'shiftseries' ? 'series' : 'rotation',
          qualifications: item.staffNurseQualificationFilters,
          status: item.status,
          notes: item.note,
          specialty: item.speciality ? { ...item.speciality, name: item.speciality.title } : item.speciality,
          timeZone: item.timeZone || '-00:00',
          assignee: item.staff
            ? {
                avatar: item.staff.avatar,
                name: `${item.staff.firstName || ''} ${item.staff.lastName || ''}`,
                assignedByClient: false,
              }
            : null,
        }) as Rotation,
    ),
    total: response.data.totalCount,
  };

  // const data = shiftsMock.slice(page * pageSize, (page + 1) * pageSize);
  // const fake = async () =>
  //   new Promise<{ data: Rotation[]; meta: { total: number } }>((resolve) => {
  //     setTimeout(() => {
  //       if (org === '2') {
  //         resolve({
  //           data: [],
  //           meta: {
  //             total: 0,
  //           },
  //         });
  //       } else {
  //         resolve({
  //           data,
  //           meta: {
  //             total: shiftsMock.length,
  //           },
  //         });
  //       }
  //     }, 1000);
  //   });

  // return await fake();
}

const shiftsMock = generateRowsMock();

function generateRowsMock(): Rotation[] {
  return Array.from({ length: 100 }, (_, index) => ({
    id: `${index + 1}`,
    duration: 2419200 * 1000,
    shiftStart: new Date(
      `2022-01-${((Math.round(index / 3) % 29) + 1)
        .toString()
        .padStart(2, '0')}T${randomFromArray(['10', '16', '22'])}:00:00`,
    ),
    shiftEnd: new Date(
      `2022-01-${((Math.round(index / 3) % 29) + 1)
        .toString()
        .padStart(2, '0')}T${randomFromArray(['10', '16', '22'])}:00:00`,
    ),
    shiftLength: 2419200 * 1000,
    recurrence: randomFromArray(['series', 'rotation']),
    location: {
      name: 'Hill Valley Gig Harbor',
      address1: '123 Main St',
      address2: 'Suite 100',
      city: 'Los Angeles',
      state: 'CA',
    },
    qualifications: [
      {
        abbreviation: 'CNA',
        category: '',
        id: index + '',
        name: 'Certified Nursing Assistant',
      },
    ],
    specialty: randomFromArray([
      {
        category: '',
        id: '',
        directQualifications: [],
        localizationKey: '',
        otherQualifications: [],
        name: 'Hematology',
      },
      null,
    ]),
    status: randomFromArray(['assigned', 'reAssigned', 'pending', 'inProgress', 'completed', 'canceled', 'na']),
    notes: randomFromArray([
      'Some note',
      undefined,
      '',
      'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.',
    ]),
    assignee: randomFromArray([
      null,
      { avatar: '', name: 'John Doe', assignedByClient: false },
      { avatar: '', name: 'John Doe', assignedByClient: true },
    ]),
    timeZone: '+00:00',
  }));
}

function randomFromArray<T>(array: T[]): T {
  return array[Math.floor(Math.random() * array.length)];
}
