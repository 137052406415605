import { useEffect, useState } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import dayjs, { Dayjs } from 'dayjs';
import { Box, ClosableDrawer } from '@atoms/layout';
import { useDirectQualificationsStore, useOrgQualificationsStore, useOrgSpecialtiesStore } from '@libs/store/settings';
import { Chip, FormControlLabel, Grid, IconButton, Stack, styled, Switch, Typography } from '@mui/material';
import { Button } from '@atoms/buttons';
import { useOrgDepartmentsStore } from '@libs/store/settings/departments';
import { DateHelper } from '@bryntum/schedulerpro';
import { useForm } from 'react-hook-form';
import { Form } from '@molecules/form/form';
import {
  CollapsibleFormBlock,
  FormBlock,
  FormCheckbox,
  FormDatePicker,
  FormRadioGroup,
  FormSelect,
  FormTextField,
  FormTimePicker,
} from '@molecules/form';
import { useLocationStore } from '@libs/store/locations';
import { SingleShift } from '@libs/models/shifts/shift';
import { useNotification } from '@libs/snackbar';
import { useTalentsStore } from '@libs/store/talents';
import { useShiftSeriesAddEditStore } from '@libs/store/shifts/shiftSeriesAddEditStore';
import { Schedule, ShiftSeriesUpdate } from '@libs/models/shifts/series';
import { TimePicker } from '@atoms/inputs';

import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import DeleteOutlineIcon from '@mui/icons-material/DeleteOutline';
import AddIcon from '@mui/icons-material/Add';
import { DayOfWeekFull } from '@libs/models/common';
import { QualificationSelect } from './qualificationSelect';
import { AssigneesSelect } from './assigneesSelect';

type EditSeriesDrawerProps = {
  onSaveCall?: () => void;
};
export function EditSeriesDrawer({ onSaveCall }: EditSeriesDrawerProps) {
  const intl = useIntl();
  const { showError } = useNotification();
  const {
    drawerOpen,
    loading: seriesLoading,
    data: currentSeriesDetails,
    seriesId,
    closeShiftSeriesDrawer,
    load,
    drawerMode,
    createSeries,
  } = useShiftSeriesAddEditStore();
  const { locations, loadLocations, loading: locationsLoading, currentLocationID, loadSimple } = useLocationStore();
  const locationOptions = locations.map((l) => ({
    key: l.id,
    label: l.name,
    value: l.id,
  }));

  useEffect(() => {
    loadLocations();
  }, [loadLocations]);

  useEffect(() => {
    if (!currentLocationID) {
      loadSimple();
    }
  }, [currentLocationID]);

  const qualStore = useDirectQualificationsStore();
  const { load: loadDirectQualifications, loading: isDirectQualListLoading } = qualStore;
  const directQualifications = qualStore.data || [];

  const orgQualStore = useOrgQualificationsStore();
  const { orgQualifications, load: loadOrgQuals, loading: isOrgQualListLoading } = orgQualStore;

  const { loadSpecialtiesList: loadSpecialties, orgSpecialties, loading: specialtiesLading } = useOrgSpecialtiesStore();
  const orgSpecialtiesOptions = orgSpecialties.map((spec) => ({
    key: spec.id,
    label: spec.name,
    value: spec.id,
  }));
  useEffect(() => {
    loadSpecialties();
  }, [loadSpecialties]);

  const { loadTree, departments, departmentsTreeLoading } = useOrgDepartmentsStore();
  const realDepartments = departments.filter((dep) => dep.type === 'department');
  const departmentsOptions = realDepartments.map((dep) => ({
    key: dep.id,
    label: dep.label,
    value: dep.id,
  }));
  const subDepartments = departments.filter((dep) => dep.type === 'subdepartment');
  const subDepartmentsOptions = subDepartments.map((dep) => ({
    key: dep.id,
    label: dep.label,
    value: dep.id,
  }));
  useEffect(() => {
    loadTree();
  }, [loadTree]);

  const { load: loadProviders, talents, loading: talentsLoading } = useTalentsStore();

  useEffect(() => {
    // TODO: use autocomplete
    loadProviders({ size: 50 });
  }, [loadProviders]);

  const isAddDialog = drawerMode == 'add';
  const localizationSubset = isAddDialog ? 'shifts.addSeriesDrawer' : 'shifts.editSeriesDrawer';
  const open = drawerOpen;

  const loading =
    seriesLoading ||
    isOrgQualListLoading ||
    locationsLoading ||
    talentsLoading ||
    departmentsTreeLoading ||
    specialtiesLading ||
    isDirectQualListLoading;

  const initial: ShiftSeriesUpdate = {
    numberOfCopies: 0, // positive int
    assignees: [], // array of ids
    staffingType: 'All',
    startDate: new Date(),
    endDate: new Date(),
    schedule: [],
    departmentNodeId: '',
    specialityId: '',
    locationId: '',
    timeZone: '',
    staffNurseQualificationFilterIds: [], // Qualification ids
    staffOtherQualificationFilterIds: [], // Qualification ids
    tags: [],
    note: '',
    isUrgent: false,
    subDepartmentNodeId: '',
    isNeedToPublish: false,
  };

  const [currentShift, setCurrentShift] = useState<ShiftSeriesUpdate>({
    ...initial,
    ...(currentSeriesDetails || {}),
  });

  const methods = useForm<ShiftSeriesFormData>({
    defaultValues: {
      template: '',
      numberOfCopies: 1,
      isUrgent: currentShift.isUrgent,

      durationType: 'setEndDate',
      startDate: null, //dayjs(currentShift.startDate),
      endDate: null, //dayjs(currentShift.endDate),

      seriesPreset: '',
      shiftTypePreset: '',
      startTime: null, //dayjs(currentShift.startDate),
      endTime: null, //dayjs(currentShift.startDate),

      schedule: {
        Sunday: [],
        Monday: [],
        Tuesday: [],
        Wednesday: [],
        Thursday: [],
        Friday: [],
        Saturday: [],
      },

      location: currentLocationID || '',
      department: '',
      subDepartment: '',
      speciality: '',

      staffingType: 'All',
      qualifications: [],
      assignees: [],

      tags: '',
      notes: '',
    },
  });
  const { setFocus, setValue, getValues, setError, watch } = methods;

  const [scheduledTimeOfAWeek, setScheduledTimeOfAWeek] = useState<number>(0); // Seconds !!!
  const durationType = watch('durationType');
  const scheduleList = watch('schedule');

  useEffect(() => {
    if (seriesId) {
      load(seriesId);
    }
  }, [seriesId]);

  useEffect(() => {
    if (!orgQualifications) {
      loadOrgQuals();
    }
  }, [orgQualifications]);

  useEffect(() => {
    setCurrentShift({
      ...currentShift,
      ...currentSeriesDetails,
    });
  }, [currentSeriesDetails]);

  useEffect(() => {
    if (!directQualifications.length) {
      loadDirectQualifications();
    }
  }, [loadDirectQualifications]);

  const handleClose = () => {
    closeShiftSeriesDrawer();
    methods.reset();
  };

  const handleSave = async () => {
    await methods.handleSubmit(handleSubmit.bind(handleSubmit, false))();
    onSaveCall && onSaveCall();
  };

  const handlePublish = async () => {
    await methods.handleSubmit(handleSubmit.bind(handleSubmit, true))();
    onSaveCall && onSaveCall();
  };

  const validateFormData = (data: ShiftSeriesFormData) => {
    if (data.endDate && data.startDate!.toDate() > data.endDate!.toDate()) {
      setError('endDate', {
        message: intl.formatMessage({ id: 'shifts.addSeriesDrawer.dateAndDuration.endDate.endDateLessError' }),
        type: 'maxLength',
      });
      return false;
    }
    const schedule = Object.keys(scheduleList).reduce((acc, dayName) => {
      return [...acc, ...scheduleList[dayName as DayOfWeekFull]];
    }, [] as Schedule[]);

    if (schedule.length === 0) {
      showError("Series can't be created with empty schedule");
      return false;
    }

    if (schedule.some((item) => 0 >= item.durationInSeconds)) {
      showError('One or more schedule items are empty or invalid');
      return false;
    }

    const days = Object.keys(scheduleList).reduce((acc, dayName)=>{
      if(scheduleList[dayName as DayOfWeekFull].length && !acc.includes(dayName)){
        acc.push(dayName);
      }
      return acc;
    },[] as string[]);


    return true;
  };

  const handleSubmit = async (isPublish: boolean, data: ShiftSeriesFormData) => {
    if (!validateFormData(data)) {
      return;
    }
    const locationDetails = locations.find((loc) => loc.id === data.location);
    let timezone = '';
    if (locationDetails) {
      timezone = (locationDetails.timeZone.match(/^[+-]{0,1}\d{0,2}:\d{0,2}/) || [])[0] || '';
    }
    const tagsParsed = (data.tags || '').split(',').map((tag) => tag.trim());

    const dataToSend: ShiftSeriesUpdate = {
      numberOfCopies: parseInt(''+data.numberOfCopies,10),
      assignees: data.assignees,
      staffingType: data.staffingType,
      startDate: data.startDate!.tz('+00:00').toDate(), // UTC IMPORTANT
      endDate: data.endDate?.tz('+00:00').toDate() || null,
      schedule: Object.keys(scheduleList).reduce((acc, dayName) => {
        return [...acc, ...scheduleList[dayName as DayOfWeekFull]];
      }, [] as Schedule[]),
      departmentNodeId: data.department,
      specialityId: data.speciality,
      locationId: data.location,
      timeZone: timezone,
      staffNurseQualificationFilterIds: data.qualifications,
      staffOtherQualificationFilterIds: [],
      tags: tagsParsed,
      note: data.notes,
      isUrgent: !!data.isUrgent,
      subDepartmentNodeId: data.subDepartment,
      isNeedToPublish: isPublish,
    };
    try {
      await createSeries(dataToSend);
      handleClose();
    } catch (error) {
      if (error instanceof Error) {
        showError(error.message);
      }
    }
  };

  const handleCancel = handleClose;



  const handleScheduleDayChange = (dayMark: DayMark, dayRangeSet: TimeRangeRecord[]) => {
    scheduleList[dayMark] = dayRangeSet.map((range) => {
      return {
        dayOfWeek: dayMark,
        startTime: range.start?.format('hh:mm:ss') || '00:00:00',
        durationInSeconds: dayjs(range.end || range.start).diff(range.start) / 1000,
      };
    });

    setValue('schedule', scheduleList);
  };
  useEffect(() => {
    const timeAtWeek: number = Object.keys(scheduleList).reduce((acc, dayName) => {
      const timeOfDay = scheduleList[dayName as DayOfWeekFull].reduce((acc, timeset) => {
        const timeOfSection = timeset.durationInSeconds;
        return acc + (timeOfSection || 0);
      }, 0);
      return acc + timeOfDay;
    }, 0);
    setScheduledTimeOfAWeek(timeAtWeek);
  }, [scheduleList]);

  const defaultStartTime = watch('startTime');
  const defaultEndTime = watch('endTime');
  const assigneesIds = watch('assignees');
  const assigned = talents.filter((talent) => assigneesIds.includes(talent.id));

  const startDate = watch('startDate');
  const endDate = watch('endDate');
  let daysRange: string[] | null = null;

  if(startDate && endDate) {
    const numberOfDaysInRange = dayjs.duration(endDate.diff(startDate)).asDays();
    daysRange = (new Array(numberOfDaysInRange+1)).fill('').map((_,idx)=>startDate.add(idx, 'days').format('dddd'))
  }

  return (
    <ClosableDrawer
      open={open}
      loading={loading}
      onClose={handleClose}
      controls={
        <Stack direction={'row-reverse'} spacing={2} sx={(theme) => ({ paddingBottom: theme.spacing(1) })}>
          <MenuButton variant="contained" onClick={handlePublish} disabled={loading}>
            <FormattedMessage id={`${localizationSubset}.menuButtons.publish.label`} />
          </MenuButton>
          <MenuButton variant="outlined" onClick={handleSave} disabled={loading}>
            <FormattedMessage id="common.save" />
          </MenuButton>
          <Box flex={1} />
          <MenuButton
            onClick={handleCancel}
            sx={(theme) => ({ color: theme.palette.text.secondary })}
            disabled={loading}
          >
            <FormattedMessage id={`${localizationSubset}.menuButtons.back.label`} />
          </MenuButton>
        </Stack>
      }
    >
      <Stack direction={'column'} sx={{ height: '100%' }} spacing={2} width={{ xs: '300px', sm: '600px' }}>
        {/* Header */}
        <Stack direction={'row'}>
          <Typography variant="h5">
            <FormattedMessage id={`${localizationSubset}.dialogHeading`} />
          </Typography>
          <Box flex={1} />
        </Stack>
        <Form formUtils={methods} style={{ height: '100%' }} onSubmit={handleSubmit.bind(handleSubmit, false)}>
          {/* Shift configuration */}
          <FormBlock title={<FormattedMessage id={`${localizationSubset}.shiftConfiguration.sectTitle`} />}>
            <Stack direction={'row'} spacing={2}>
              <FormSelect
                disabled
                label={<FormattedMessage id={`${localizationSubset}.shiftConfiguration.shiftTemplate.label`} />}
                placeholder={intl.formatMessage({
                  id: `${localizationSubset}.shiftConfiguration.shiftTemplate.placeholder`,
                })}
                fullWidth
                name="template"
                options={[]}
              />
              <FormTextField
                disabled={loading}
                label={<FormattedMessage id={`${localizationSubset}.shiftConfiguration.quantity.label`} />}
                fullWidth
                name="numberOfCopies"
              />
            </Stack>
            <FormCheckbox
              name="isUrgent"
              label={<FormattedMessage id={`${localizationSubset}.shiftConfiguration.markAsUrgent.label`} />}
              size="medium"
            />
          </FormBlock>

          {/* Date and duration */}
          <FormBlock title={<FormattedMessage id={`${localizationSubset}.dateAndDuration.sectTitle`} />}>
            <Stack direction={'column'} spacing={1}>
              <FormRadioGroup
                name="durationType"
                label={<FormattedMessage id={`${localizationSubset}.dateAndDuration.durationType.label`} />}
                items={[
                  {
                    label: (
                      <FormattedMessage id={`${localizationSubset}.dateAndDuration.durationType.items.setEndDate`} />
                    ),
                    value: 'setEndDate',
                  },
                  {
                    label: (
                      <FormattedMessage id={`${localizationSubset}.dateAndDuration.durationType.items.infiniteLoop`} />
                    ),
                    value: 'infiniteLoop',
                  },
                ]}
                required
                disabled={loading}
                row
              />
              {/* <Stack direction={'row'} spacing={2}> */}
              <Grid container direction={'row'}>
                <Grid item xs={6} sx={{ paddingRight: '8px' }}>
                  <FormDatePicker
                    disabled={loading}
                    label={<FormattedMessage id={`${localizationSubset}.dateAndDuration.startDate.label`} />}
                    fullWidth
                    minDate={dayjs(new Date())}
                    name="startDate"
                    defaultValue={null}
                    disablePast
                    required
                  />
                </Grid>
                <Grid item xs={6} sx={{ paddingLeft: '8px' }}>
                  {durationType === 'setEndDate' && (
                    <FormDatePicker
                      disabled={loading}
                      label={<FormattedMessage id={`${localizationSubset}.dateAndDuration.endDate.label`} />}
                      fullWidth
                      name="endDate"
                      minDate={dayjs(new Date())}
                      disablePast
                      defaultValue={null}
                      required
                    />
                  )}
                </Grid>
              </Grid>
              {/* </Stack> */}
            </Stack>
          </FormBlock>
          {/* Series details */}
          <FormBlock title={<FormattedMessage id={`${localizationSubset}.seriesDetails.sectTitle`} />}>
            <Stack direction={'row'} spacing={2}>
              <FormSelect
                disabled
                label={<FormattedMessage id={`${localizationSubset}.seriesDetails.seriesPreset.label`} />}
                placeholder={intl.formatMessage({
                  id: `${localizationSubset}.seriesDetails.seriesPreset.placeholder`,
                })}
                fullWidth
                name="seriesPreset"
                options={[]}
              />
              <FormSelect
                disabled
                label={<FormattedMessage id={`${localizationSubset}.seriesDetails.shiftTypePreset.label`} />}
                placeholder={intl.formatMessage({
                  id: `${localizationSubset}.seriesDetails.shiftTypePreset.placeholder`,
                })}
                fullWidth
                name="shiftTypePreset"
                options={[]}
              />
            </Stack>
            <Stack direction={'row'} spacing={2}>
              <FormTimePicker
                disabled={loading}
                label={<FormattedMessage id={`${localizationSubset}.seriesDetails.startTime.label`} />}
                fullWidth
                name="startTime"
                defaultValue={null}
                required
              />
              <FormTimePicker
                disabled={loading}
                label={<FormattedMessage id={`${localizationSubset}.seriesDetails.endTime.label`} />}
                fullWidth
                name="endTime"
                defaultValue={null}
                required
              />
            </Stack>
          </FormBlock>
          {/* Series schedule */}
          <CollapsibleFormBlock
            expanded={Object.keys(scheduleList).some((dayMark) => !!scheduleList[dayMark as DayMark].length)}
            sectTitle={<FormattedMessage id={`${localizationSubset}.seriesSchedule.sectTitle`} />}
            sectTitleBadges={
              <Chip
                size="small"
                label={intl.formatMessage(
                  { id: `${localizationSubset}.seriesSchedule.sectBadges.spendTime` },
                  { amount: `${Math.ceil(DateHelper.as('hours', scheduledTimeOfAWeek * 1000))}h` },
                )}
              />
            }
          >
            <Stack direction={'column'} spacing={2}>
              {Object.keys(scheduleList).map((dayMark: string, idx) => {

                return (
                  <DayScheduleSection
                    key={idx}
                    day={dayMark}
                    disabled={!daysRange?.includes(dayMark)}
                    defaultStartTime={defaultStartTime}
                    defaultEndTime={defaultEndTime}
                    localizationSubset={`${localizationSubset}.seriesSchedule.daySchedule`}
                    onChange={(dayRangeSet) => {
                      handleScheduleDayChange(dayMark as DayMark, dayRangeSet);
                    }}
                  />
                );
              })}
            </Stack>
            <Grid container>
              <Grid item xs={6}>
                <Stack direction={'column'} sx={{ height: '100%' }}>
                  {/* Add typography with label here */}
                  <Box flex={1} />
                  <FormCheckbox
                    name="shouldSavePreset"
                    disabled
                    label={
                      <FormattedMessage id={`${localizationSubset}.seriesSchedule.preset.seriesPresetCheckbox.label`} />
                    }
                    size="large"
                  />
                </Stack>
              </Grid>
              <Grid item xs={6}>
                <FormTextField
                  disabled={true} //{loading}
                  label={<FormattedMessage id={`${localizationSubset}.seriesSchedule.preset.presetName.label`} />}
                  placeholder={intl.formatMessage({
                    id: `${localizationSubset}.seriesSchedule.preset.presetName.placeholder`,
                  })}
                  fullWidth
                  name="presetName"
                />
              </Grid>
            </Grid>
          </CollapsibleFormBlock>
          {/* Location */}
          <FormBlock title={<FormattedMessage id={`${localizationSubset}.location.sectTitle`} />}>
            <Stack direction={'row'} spacing={2}>
              <FormSelect
                disabled={loading}
                label={<FormattedMessage id={`${localizationSubset}.location.locationName.label`} />}
                placeholder={intl.formatMessage({
                  id: `${localizationSubset}.location.locationName.placeholder`,
                })}
                fullWidth
                name="location"
                options={locationOptions}
                required
              />
              <FormSelect
                disabled={loading}
                label={<FormattedMessage id={`${localizationSubset}.location.department.label`} />}
                placeholder={intl.formatMessage({
                  id: `${localizationSubset}.location.department.placeholder`,
                })}
                fullWidth
                required
                name="department"
                options={departmentsOptions}
              />
            </Stack>
            <Stack direction={'row'} spacing={2}>
              <FormSelect
                disabled={loading}
                label={<FormattedMessage id={`${localizationSubset}.location.subDepartment.label`} />}
                placeholder={intl.formatMessage({
                  id: `${localizationSubset}.location.subDepartment.placeholder`,
                })}
                fullWidth
                name="subDepartment"
                options={subDepartmentsOptions}
              />
              <FormSelect
                disabled={loading}
                label={<FormattedMessage id={`${localizationSubset}.location.specialty.label`} />}
                placeholder={intl.formatMessage({
                  id: `${localizationSubset}.location.specialty.placeholder`,
                })}
                fullWidth
                name="speciality"
                options={orgSpecialtiesOptions}
              />
            </Stack>
          </FormBlock>

          {/* Talent */}
          <FormBlock title={<FormattedMessage id={`${localizationSubset}.talentConfiguration.sectTitle`} />}>
            <Grid container spacing={2} sx={{ padding: '16px' }}>
              <Grid xs={12}>
                <FormRadioGroup
                  name="staffingType"
                  label={<FormattedMessage id={`${localizationSubset}.talentConfiguration.talentType.label`} />}
                  items={[
                    {
                      label: <FormattedMessage id={`${localizationSubset}.talentConfiguration.talentType.items.all`} />,
                      value: 'All',
                    },
                    {
                      label: (
                        <FormattedMessage id={`${localizationSubset}.talentConfiguration.talentType.items.internal`} />
                      ),
                      value: 'Internal',
                    },
                    {
                      label: (
                        <FormattedMessage id={`${localizationSubset}.talentConfiguration.talentType.items.external`} />
                      ),
                      value: 'External',
                    },
                  ]}
                  required
                  disabled={loading}
                  row
                />
              </Grid>
              <Grid xs={12}>
                <QualificationSelect
                  fullWidth
                  name="qualifications"
                  required
                  localizationSubset={localizationSubset}
                  disabledOptions={assigned.flatMap((a) => a.nurseQualifications.map((q) => q.id))}
                />
              </Grid>
              <Grid xs={12}>
                <AssigneesSelect
                  fullWidth
                  name="assignees"
                  localizationSubset={localizationSubset}
                  selectedQualifications={watch('qualifications')}
                  disabled={watch('qualifications').length === 0}
                />
              </Grid>
            </Grid>
          </FormBlock>

          {/* Additional info */}
          <FormBlock title={<FormattedMessage id={`${localizationSubset}.additionalInfo.sectTitle`} />}>
            <Grid container spacing={2}>
              <Grid item xs={6}>
                <FormTextField
                  disabled={loading}
                  label={<FormattedMessage id={`${localizationSubset}.additionalInfo.tag.label`} />}
                  placeholder={intl.formatMessage({
                    id: `${localizationSubset}.additionalInfo.tag.placeholder`,
                  })}
                  fullWidth
                  name="tags"
                />
              </Grid>
            </Grid>
          </FormBlock>
          <CollapsibleFormBlock
            sectTitle={<FormattedMessage id={`${localizationSubset}.additionalInfo.notes.sectTitle`} />}
          >
            <FormTextField
              name="notes"
              fullWidth
              InputProps={{
                sx: { height: '150px' },
              }}
            />
          </CollapsibleFormBlock>
        </Form>
      </Stack>
    </ClosableDrawer>
  );
}

type TimeRangeRecord = {
  start: Dayjs | null;
  end: Dayjs | null;
};

type DayScheduleSectionProps = {
  day: string;
  localizationSubset: string;
  onChange: (rangeSet: TimeRangeRecord[]) => void;
  defaultStartTime?: Dayjs | null;
  defaultEndTime?: Dayjs | null;
  disabled: boolean;
};

function DayScheduleSection({
  day,
  localizationSubset,
  onChange,
  defaultStartTime = null,
  defaultEndTime = null,
  disabled= false,
}: DayScheduleSectionProps) {
  const [enabled, setEnabled] = useState<boolean>(false);
  const [timeSet, setTimeSet] = useState<TimeRangeRecord[]>([]);
  const intl = useIntl();

  const handleEnabled = (evt: React.SyntheticEvent, value: boolean) => {
    if (!timeSet.length) {
      handleAdd();
    }
    setEnabled(value);
  };

  const handleDelete = (index: number) => {
    const newVer = [...timeSet];
    newVer.splice(index, 1);
    setTimeSet(newVer);
    onChange && onChange(timeSet);
  };

  const handleAdd = () => {
    const newVer = [...timeSet, { start: defaultStartTime, end: defaultEndTime }]
    setTimeSet(newVer);
    onChange && onChange(newVer);
  };

  const handleCopy = () => {};

  const handleStartChange = (startTime: Dayjs | null, elementIndex: number) => {
    const newVer = [...timeSet];
    const changing = newVer[elementIndex];
    newVer[elementIndex] = { ...changing, start: startTime };
    setTimeSet(newVer);
    onChange && onChange(newVer);
  };

  const handleEndChange = (endTime: Dayjs | null, elementIndex: number) => {
    const newVer = [...timeSet];
    const changing = newVer[elementIndex];
    newVer[elementIndex] = { ...changing, end: endTime };
    setTimeSet(newVer);
    onChange && onChange(newVer);
  };

  return (
    <Grid container direction={'row'}>
      <Grid item xs={3}>
        <FormControlLabel
          onChange={handleEnabled}
          value={enabled}
          disabled={disabled}
          control={<Switch />}
          label={intl.formatMessage({ id: `common.weekDays.${day.toLowerCase()}` })}
        />
      </Grid>
      <Grid item xs={9} spacing={2}>
        {enabled && (
          <Stack direction={'column'} spacing={2}>
            {timeSet.map((range, idx) => (
              <Stack direction={'row'} spacing={2} key={idx}>
                <TimePicker
                  fullWidth
                  defaultValue={defaultStartTime}
                  value={range.start}
                  onChange={(value) => {
                    handleStartChange(value, idx);
                  }}
                />
                <TimePicker
                  fullWidth
                  defaultValue={defaultEndTime}
                  value={range.end}
                  onChange={(value) => {
                    handleEndChange(value, idx);
                  }}
                />
                <Stack direction={'row'} spacing={0}>
                  {idx === 0 ? (
                    <HoverAbleIconButton size="small" onClick={handleAdd}>
                      <AddIcon />
                    </HoverAbleIconButton>
                  ) : (
                    <HoverAbleIconButton
                      color="error"
                      size="small"
                      onClick={() => {
                        handleDelete(idx);
                      }}
                    >
                      <DeleteOutlineIcon />
                    </HoverAbleIconButton>
                  )}
                  {idx === 0 ? (
                    <IconButton size="small" onClick={handleCopy} disabled>
                      <ContentCopyIcon />
                    </IconButton>
                  ) : (
                    <Box sx={{ width: '32px' }} />
                  )}
                </Stack>
              </Stack>
            ))}
          </Stack>
        )}
      </Grid>
    </Grid>
  );
}

const HoverAbleIconButton = styled(IconButton)(({ theme }) => ({
  opacity: 0,
  ':hover': {
    opacity: 1,
  },
}));

const MenuButton = styled(Button)(({ theme }) => ({
  minWidth: theme.spacing(16.75),
}));

type DayMark = DayOfWeekFull;

type ShiftSeriesFormData = {
  template: string;
  numberOfCopies: number;
  isUrgent: boolean;

  durationType: 'setEndDate' | 'infiniteLoop';
  startDate: Dayjs | null;
  endDate: Dayjs | null;

  seriesPreset: string;
  shiftTypePreset: string;
  startTime: Dayjs | null;
  endTime: Dayjs | null;

  schedule: Record<DayMark, Schedule[]>;

  location: string;
  department: string;
  subDepartment: string;
  speciality: string;

  staffingType: SingleShift['staffingType'];
  qualifications: string[];
  assignees: string[];

  tags: string;
  notes: string;
};
