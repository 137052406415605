import { create } from 'zustand';
import { fetchShiftsList } from '@libs/api/shifts';
import { Shift } from '@libs/models/shifts';
import { ShiftOld } from '@libs/models/shifts/shift';
import { Filter } from '@libs/models/common';

type State = {
  loading: boolean;
  initiated: boolean;
  data: ShiftOld[];
  page: number;
  total: number;
  perPage: number;
  activeFilters: Filter[];
  loadShifts: (page: number, perPage: number, filters?: Filter[]) => Promise<void>;
  reloadCurrentFilter: () => Promise<void>;
  showDetails: boolean;
  currentShift: ShiftOld | null;
  openShiftDetails: (id: string) => void;
  closeShiftDetails: () => void;
};

export const useShiftsListStore = create<State>()((set, get) => ({
  loading: false,
  initiated: false,
  data: [],
  page: 1,
  activeFilters: [],
  perPage: 10,
  total: 0,

  loadShifts: async (page, perPage, filters = []) => {
    set({ loading: true });
    try {
      const data = await fetchShiftsList(page, perPage, filters);
      set({
        loading: false,
        data: data.items,
        activeFilters: filters,
        page,
        perPage,
        total: data.total,
      });
    } finally {
      set({ loading: false, initiated: true });
    }
  },
  reloadCurrentFilter: async () => {
    const { page, perPage, activeFilters } = get();
    set({ loading: true });
    try {
      const data = await fetchShiftsList(page, perPage, activeFilters);
      set({
        loading: false,
        data: data.items,
        page,
        activeFilters,
        perPage,
        total: data.total,
      });
    } finally {
      set({ loading: false });
    }
  },
  currentShift: null,
  showDetails: false,
  openShiftDetails: (id) => {
    const { data } = get();
    const shift = data.find((s) => s.id === id);
    set({ currentShift: shift, showDetails: true });
  },
  closeShiftDetails: () => {
    set({ showDetails: false });
  },
}));
