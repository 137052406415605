import { useMemo } from 'react';
import { FormattedMessage } from 'react-intl';
import Box from '@mui/material/Box';
import { useCurrentAgency } from '@libs/store/agency';
import { useShiftsFilter } from '@libs/store/shifts';
import { ShiftsIcon } from '@atoms/icons';
import { EmptyListPlaceholder, isDateSeparator, separatorClass } from '@molecules/dataDisplay';
import { DataGrid } from '@molecules/dataDisplay';
import { useRotationsListColumns } from './shifts/columns';
import { useRotationsListStore } from '@libs/store/shifts/rotationsList';
import { useLocationStore } from '@libs/store/locations';

export function SeriesNRotationsTable() {
  const { data, loadSeriesNRotations, loading: rotationsListLoading, total, page, perPage, openShiftDetails } = useRotationsListStore();
  const currentAgencyState = useCurrentAgency();
  const { activeFilters } = useShiftsFilter();
  const agencyId = currentAgencyState.id;
  const { currentLocationID } = useLocationStore();

  const loading = rotationsListLoading;

  useMemo(() => {
    const filters = [...activeFilters];
    filters.push({ key: 'locations', value: [currentLocationID] });
    loadSeriesNRotations(1, 10, activeFilters);
  }, [loadSeriesNRotations, agencyId]);

  return (
    <Box sx={{ height: '100%', width: '100%' }}>
      <DataGrid
        rows={data}
        loading={loading}
        rowHeight={64}
        columns={useRotationsListColumns()}
        initialState={{
          pagination: {
            paginationModel: {
              pageSize: 10,
            },
          },
        }}
        paginationModel={{
          pageSize: perPage,
          page: page,
        }}
        rowCount={total}
        onPaginationModelChange={(params) => {
          loadSeriesNRotations(params.page, params.pageSize, activeFilters);
        }}
        pageSizeOptions={[10, 20]}
        disableRowSelectionOnClick
        getRowHeight={({ model }) => {
          if (model.type === 'separator') {
            return 44;
          }

          return null;
        }}
        getRowClassName={(params) => {
          if (isDateSeparator(params.row)) {
            return separatorClass;
          }
          return '';
        }}
        noRowsOverlay={
          <EmptyListPlaceholder
            icon={<ShiftsIcon active />}
            title={<FormattedMessage id="shifts.table.noRows.title" />}
            subtitle={<FormattedMessage id="shifts.table.noRows.subtitle" />}
          />
        }
        onRowClick={(params) => {
          openShiftDetails(`${params.id}`);
        }}
      />
    </Box>
  );
}

