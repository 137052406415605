import { create } from 'zustand';
import { createSingleShift, getEvents } from '@libs/api/shifts';
import { Shift, ShiftList, SingleShift } from '@libs/models/shifts/shift';
import { DateHelper } from '@bryntum/schedulerpro';
import dayjs, { Dayjs } from 'dayjs';

type DateFilterValue = { from: Dayjs; to: Dayjs };

type State = {
  loading: boolean;
  drawerOpen: boolean;
  rotationId: string;
  drawerMode: 'add' | 'edit';
  initiated: boolean;
  shiftsToPublish: ShiftList[];
  loadPublishableShifts: (dateFilter: DateFilterValue | null) => void;
  openShiftPublishDrawer: (id?: string) => void;
  closeShiftPublishDrawer: () => void;
};

export const usePublishDrawerStore = create<State>()((set, get) => ({
  loading: false,
  drawerOpen: false,
  drawerMode: 'add',
  initiated: false,
  rotationId: '',
  shiftsToPublish: [],
  loadPublishableShifts: async (dateFilter) => {
    set({ loading: true });

    try {
      const startDate = dateFilter
        ? dateFilter?.from.toDate().toISOString()
        : DateHelper.startOf(new Date(), 'week', undefined, 1).toISOString();
      const endDate = dateFilter
        ? dateFilter.to.toDate().toISOString()
        : DateHelper.add(DateHelper.startOf(new Date(), 'week', undefined, 1), 7, 'days').toISOString();

      const resp = await getEvents(startDate, endDate, [{ key: 'statuses', value: ['Opened'] }]);
      set({
        loading: false,
        shiftsToPublish: resp.data,
      });
    } finally {
      set({ loading: false, initiated: true });
    }
  },
  openShiftPublishDrawer: (id) => {
    set({ drawerMode: id ? 'edit' : 'add', rotationId: id, drawerOpen: true });
  },
  closeShiftPublishDrawer: () => {
    set({ drawerOpen: false });
  },
}));
