import { SyntheticEvent, useEffect, useState } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { Dayjs } from 'dayjs';
import { Box, ClosableDrawer } from '@atoms/layout';
import { useDirectQualificationsStore, useOrgQualificationsStore, useOrgSpecialtiesStore } from '@libs/store/settings';
import {
  Chip,
  FormControlLabel,
  Grid,
  IconButton,
  Stack,
  styled,
  Switch,
  Tab,
  TextField,
  Typography,
} from '@mui/material';
import { Button } from '@atoms/buttons';
import { useOrgDepartmentsStore } from '@libs/store/settings/departments';
import { useForm } from 'react-hook-form';
import { Form } from '@molecules/form/form';
import {
  CollapsibleFormBlock,
  FormBlock,
  FormCheckbox,
  FormDatePicker,
  FormRadioGroup,
  FormSelect,
  FormTextField,
  FormTimePicker,
} from '@molecules/form';
import { useLocationStore } from '@libs/store/locations';
import { SingleShift } from '@libs/models/shifts/shift';
import { useNotification } from '@libs/snackbar';
import { useTalentsStore } from '@libs/store/talents';

import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import AddIcon from '@mui/icons-material/Add';
import { DayOfWeekFull } from '@libs/models/common';
import { useShiftRotationAddEditStore } from '@libs/store/shifts/shiftRotationAddEditStore';
import { DateTimeSlots, SequenceItem, ShiftRotationUpdate } from '@libs/models/shifts/rotation';
import { AssigneesSelect } from './assigneesSelect';
import { QualificationSelect } from './qualificationSelect';
import { RotationScheduleSection } from './rotationScheduleSection';

type EditSeriesDrawerProps = {
  onSaveCall?: () => void;
};
export function EditRotationDrawer({ onSaveCall }: EditSeriesDrawerProps) {
  const intl = useIntl();
  const { showError } = useNotification();

  const {
    drawerOpen,
    loading: rotationLoading,
    data: currentRotationDetails,
    rotationId,
    closeShiftRotationDrawer,
    load,
    drawerMode,
    createRotation,
  } = useShiftRotationAddEditStore();
  const { locations, loadLocations } = useLocationStore();
  const locationOptions = locations.map((l) => ({
    key: l.id,
    label: l.name,
    value: l.id,
  }));

  useEffect(() => {
    loadLocations();
  }, [loadLocations]);

  const qualStore = useDirectQualificationsStore();
  const { load: loadDirectQualifications, loading: isDirectListLoading } = qualStore;
  const directQualifications = qualStore.data || [];

  const orgQualStore = useOrgQualificationsStore();
  const { orgQualifications, load: loadOrgQuals, loading: isOrgQualListLoading } = orgQualStore;

  const { loadSpecialtiesList: loadSpecialties, orgSpecialties } = useOrgSpecialtiesStore();
  const orgSpecialtiesOptions = orgSpecialties.map((spec) => ({
    key: spec.id,
    label: spec.name,
    value: spec.id,
  }));
  useEffect(() => {
    loadSpecialties();
  }, [loadSpecialties]);

  const { loadTree, departments } = useOrgDepartmentsStore();
  const realDepartments = departments.filter((dep) => dep.type === 'department');
  const departmentsOptions = realDepartments.map((dep) => ({
    key: dep.id,
    label: dep.label,
    value: dep.id,
  }));
  const subDepartments = departments.filter((dep) => dep.type === 'subdepartment');
  const subDepartmentsOptions = subDepartments.map((dep) => ({
    key: dep.id,
    label: dep.label,
    value: dep.id,
  }));
  useEffect(() => {
    loadTree();
  }, [loadTree]);

  const { load: loadProviders } = useTalentsStore();

  useEffect(() => {
    // TODO: use autocomplete
    loadProviders({ size: 50 });
  }, [loadProviders]);

  const [sequence, setSequence] = useState<SequenceItem[]>([]);
  const [dateTimeSlots, setDateTimeSlots] = useState<DateTimeSlots[]>([]);

  const isAddDialog = drawerMode == 'add';
  const localizationSubset = isAddDialog ? 'shifts.addRotationDrawer' : 'shifts.editRotationDrawer';
  const open = drawerOpen;

  const loading = rotationLoading || isOrgQualListLoading;

  const initial: ShiftRotationUpdate = {
    numberOfCopies: 1, // positive int
    assignees: [], // array of ids
    staffingType: 'All',
    startDate: new Date(),
    endDate: new Date(),
    dateTimeSlots: [],
    sequence: [],
    departmentNodeId: '',
    specialityId: '',
    locationId: '',
    timeZone: '',
    staffNurseQualificationFilterIds: [], // Qualification ids
    staffOtherQualificationFilterIds: [], // Qualification ids
    tags: [],
    note: '',
    isUrgent: false,
    subDepartmentNodeId: '',
    isNeedToPublish: false,
  };

  const [currentShift, setCurrentShift] = useState<ShiftRotationUpdate>({
    ...initial,
    ...(currentRotationDetails || {}),
  });

  const methods = useForm<ShiftRotationFormData>({
    defaultValues: {
      template: '',
      numberOfCopies: 0,
      isUrgent: currentShift.isUrgent,

      durationType: 'setEndDate',
      startDate: null, //dayjs(currentShift.startDate),
      endDate: null, //dayjs(currentShift.endDate),

      seriesPreset: '',
      shiftTypePreset: '',
      startTime: null, //dayjs(currentShift.startDate),
      endTime: null, //dayjs(currentShift.startDate),

      location: '',
      department: '',
      subDepartment: '',
      speciality: '',

      staffingType: 'All',
      qualifications: [],
      assignees: [],

      tags: '',
      notes: '',
    },
  });
  const { setFocus, setValue, getValues, setError, watch } = methods;

  const [scheduledTimeOfAWeek, setScheduledTimeOfAWeek] = useState<number>(0);

  useEffect(() => {
    if (rotationId) {
      load(rotationId);
    }
  }, [rotationId]);

  useEffect(() => {
    if (!orgQualifications) {
      loadOrgQuals();
    }
  }, [orgQualifications]);

  useEffect(() => {
    setCurrentShift({
      ...currentShift,
      ...currentRotationDetails,
    });
  }, [currentRotationDetails]);

  useEffect(() => {
    if (!directQualifications.length) {
      loadDirectQualifications();
    }
  }, [loadDirectQualifications]);

  const handleClose = () => {
    closeShiftRotationDrawer();
    methods.reset();
  };

  const handleSave = async () => {
    await methods.handleSubmit(handleSubmit.bind(handleSubmit, false))();
    onSaveCall && onSaveCall();
  };

  const handlePublish = async () => {
    await methods.handleSubmit(handleSubmit.bind(handleSubmit, true))();
    onSaveCall && onSaveCall();
  };

  const handleSubmit = async (isPublish: boolean, data: ShiftRotationFormData) => {
    const locationDetails = locations.find((loc) => loc.id === data.location);
    let timezone = '';
    if (locationDetails) {
      timezone = (locationDetails.timeZone.match(/^[+-]{0,1}\d{0,2}:\d{0,2}/) || [])[0] || '';
    }
    const tagsParsed = (data.tags || '').split(',').map((tag) => tag.trim());

    const dataToSend: ShiftRotationUpdate = {
      numberOfCopies: data.numberOfCopies,
      assignees: data.assignees,
      staffingType: data.staffingType,
      startDate: data.startDate!.tz('+00:00').toDate(), // UTC IMPORTANT
      endDate: data.endDate!.tz('+00:00').toDate(),
      sequence: sequence,
      dateTimeSlots: dateTimeSlots,
      departmentNodeId: data.department,
      specialityId: data.speciality,
      locationId: data.location,
      timeZone: timezone,
      staffNurseQualificationFilterIds: data.qualifications,
      staffOtherQualificationFilterIds: [],
      tags: tagsParsed,
      note: data.notes,
      isUrgent: !!data.isUrgent,
      subDepartmentNodeId: data.subDepartment,
      isNeedToPublish: isPublish,
    };
    try {
      await createRotation(dataToSend);
      handleClose();
    } catch (error) {
      if (error instanceof Error) {
        showError(error.message);
      }
    }
  };

  const handleCancel = handleClose;

  const durationType = watch('durationType');

  return (
    <ClosableDrawer
      open={open}
      loading={loading}
      onClose={handleClose}
      controls={
        <Stack direction={'row-reverse'} spacing={2} sx={(theme) => ({ paddingBottom: theme.spacing(1) })}>
          <MenuButton variant="contained" onClick={handlePublish} disabled={loading}>
            <FormattedMessage id={`${localizationSubset}.menuButtons.publish.label`} />
          </MenuButton>
          <MenuButton variant="outlined" onClick={handleSave} disabled={loading}>
            <FormattedMessage id="common.save" />
          </MenuButton>
          <Box flex={1} />
          <MenuButton
            onClick={handleCancel}
            sx={(theme) => ({ color: theme.palette.text.secondary })}
            disabled={loading}
          >
            <FormattedMessage id={`${localizationSubset}.menuButtons.back.label`} />
          </MenuButton>
        </Stack>
      }
    >
      <Stack direction={'column'} sx={{ height: '100%' }} spacing={2} width={{ xs: '300px', sm: '600px' }}>
        {/* Header */}
        <Stack direction={'row'}>
          <Typography variant="h5">
            <FormattedMessage id={`${localizationSubset}.dialogHeading`} />
          </Typography>
          <Box flex={1} />
        </Stack>
        <Form formUtils={methods} style={{ height: '100%' }} onSubmit={handleSubmit.bind(handleSubmit, false)}>
          {/* Shift configuration */}
          <FormBlock title={<FormattedMessage id={`${localizationSubset}.shiftConfiguration.sectTitle`} />}>
            <Stack direction={'row'} spacing={2}>
              <FormSelect
                disabled
                label={<FormattedMessage id={`${localizationSubset}.shiftConfiguration.shiftTemplate.label`} />}
                placeholder={intl.formatMessage({
                  id: `${localizationSubset}.shiftConfiguration.shiftTemplate.placeholder`,
                })}
                fullWidth
                name="template"
                options={[]}
              />
              <FormTextField
                disabled={loading}
                label={<FormattedMessage id={`${localizationSubset}.shiftConfiguration.quantity.label`} />}
                fullWidth
                name="numberOfCopies"
              />
            </Stack>
            <FormCheckbox
              name="isUrgent"
              label={<FormattedMessage id={`${localizationSubset}.shiftConfiguration.markAsUrgent.label`} />}
              size="medium"
            />
          </FormBlock>

          {/* Date and duration */}
          <FormBlock title={<FormattedMessage id={`${localizationSubset}.dateAndDuration.sectTitle`} />}>
            <Stack direction={'column'} spacing={1}>
              <FormRadioGroup
                name="durationType"
                label={<FormattedMessage id={`${localizationSubset}.dateAndDuration.durationType.label`} />}
                items={[
                  {
                    label: (
                      <FormattedMessage id={`${localizationSubset}.dateAndDuration.durationType.items.setEndDate`} />
                    ),
                    value: 'setEndDate',
                  },
                  {
                    label: (
                      <FormattedMessage id={`${localizationSubset}.dateAndDuration.durationType.items.infiniteLoop`} />
                    ),
                    value: 'infiniteLoop',
                    disabled: true,
                  },
                ]}
                required
                disabled={loading}
                row
              />
              {/* <Stack direction={'row'} spacing={2}> */}
              <Grid container direction={'row'}>
                <Grid item xs={6} sx={{ paddingRight: '8px' }}>
                  <FormDatePicker
                    disabled={loading}
                    label={<FormattedMessage id={`${localizationSubset}.dateAndDuration.startDate.label`} />}
                    fullWidth
                    name="startDate"
                    defaultValue={null}
                    required
                  />
                </Grid>
                <Grid item xs={6} sx={{ paddingLeft: '8px' }}>
                  {durationType === 'setEndDate' && (
                    <FormDatePicker
                      disabled={loading}
                      label={<FormattedMessage id={`${localizationSubset}.dateAndDuration.endDate.label`} />}
                      fullWidth
                      name="endDate"
                      defaultValue={null}
                      required
                    />
                  )}
                </Grid>
              </Grid>
              {/* </Stack> */}
            </Stack>
          </FormBlock>
          {/* Series details */}
          <FormBlock title={<FormattedMessage id={`${localizationSubset}.rotationDetails.sectTitle`} />}>
            <Stack direction={'row'} spacing={2}>
              <FormSelect
                disabled
                label={<FormattedMessage id={`${localizationSubset}.rotationDetails.seriesPreset.label`} />}
                placeholder={intl.formatMessage({
                  id: `${localizationSubset}.rotationDetails.seriesPreset.placeholder`,
                })}
                fullWidth
                name="seriesPreset"
                options={[]}
              />
              <FormSelect
                disabled
                label={<FormattedMessage id={`${localizationSubset}.rotationDetails.shiftTypePreset.label`} />}
                placeholder={intl.formatMessage({
                  id: `${localizationSubset}.rotationDetails.shiftTypePreset.placeholder`,
                })}
                fullWidth
                name="shiftTypePreset"
                options={[]}
              />
            </Stack>
            <Stack direction={'row'} spacing={2}>
              <FormTimePicker
                disabled={loading}
                label={<FormattedMessage id={`${localizationSubset}.rotationDetails.startTime.label`} />}
                fullWidth
                name="startTime"
                defaultValue={null}
                required
              />
              <FormTimePicker
                disabled={loading}
                label={<FormattedMessage id={`${localizationSubset}.rotationDetails.endTime.label`} />}
                fullWidth
                name="endTime"
                defaultValue={null}
                required
              />
            </Stack>
          </FormBlock>
          {/* Rotation sequence */}
          <CollapsibleFormBlock
            sectTitle={<FormattedMessage id={`${localizationSubset}.rotationSequence.sectTitle`} />}
            sectTitleBadges={
              <Chip
                size="small"
                label={
                  sequence.length
                    ? sequence.reduce((acc, seq, idx, array) => {
                        if (array.length === 1 && array[0].durationInDays === 0) {
                          return intl.formatMessage({
                            id: `${localizationSubset}.rotationSequence.sectBadges.noSequence`,
                          });
                        }
                        const label = seq.isPause ? 'Off' : 'On';
                        return `${acc} ${seq.durationInDays}${label}`;
                      }, '')
                    : intl.formatMessage({ id: `${localizationSubset}.rotationSequence.sectBadges.noSequence` })
                }
              />
            }
          >
            <RotationSequenceSection
              localizationSubset={localizationSubset}
              onChange={(seq) => {
                setSequence(seq);
                setDateTimeSlots(
                  calcTimeSlots(watch('startDate'), watch('endDate'), watch('startTime'), watch('endTime'), seq),
                );
              }}
            />
          </CollapsibleFormBlock>
          {/* Rotation schedule preview */}
          <CollapsibleFormBlock
            sectTitle={<FormattedMessage id={`${localizationSubset}.rotationSchedulePreview.sectTitle`} />}
            sectTitleBadges={
              <Chip
                size="small"
                label={intl.formatMessage(
                  { id: `${localizationSubset}.rotationSchedulePreview.sectBadges.spendTime` },
                  { amount: scheduledTimeOfAWeek },
                )}
              />
            }
          >
            <RotationScheduleSection
              localizationSubset={localizationSubset}
              onChange={setDateTimeSlots}
              slots={dateTimeSlots}
            />
          </CollapsibleFormBlock>
          {/* Location */}
          <FormBlock title={<FormattedMessage id={`${localizationSubset}.location.sectTitle`} />}>
            <Stack direction={'row'} spacing={2}>
              <FormSelect
                disabled={loading}
                label={<FormattedMessage id={`${localizationSubset}.location.locationName.label`} />}
                placeholder={intl.formatMessage({
                  id: `${localizationSubset}.location.locationName.placeholder`,
                })}
                fullWidth
                name="location"
                options={locationOptions}
                required
              />
              <FormSelect
                disabled={loading}
                label={<FormattedMessage id={`${localizationSubset}.location.department.label`} />}
                placeholder={intl.formatMessage({
                  id: `${localizationSubset}.location.department.placeholder`,
                })}
                fullWidth
                name="department"
                options={departmentsOptions}
              />
            </Stack>
            <Stack direction={'row'} spacing={2}>
              <FormSelect
                disabled={loading}
                label={<FormattedMessage id={`${localizationSubset}.location.subDepartment.label`} />}
                placeholder={intl.formatMessage({
                  id: `${localizationSubset}.location.subDepartment.placeholder`,
                })}
                fullWidth
                name="subDepartment"
                options={subDepartmentsOptions}
              />
              <FormSelect
                disabled={true} //{loading}
                label={<FormattedMessage id={`${localizationSubset}.location.specialty.label`} />}
                placeholder={intl.formatMessage({
                  id: `${localizationSubset}.location.specialty.placeholder`,
                })}
                fullWidth
                name="speciality"
                options={orgSpecialtiesOptions}
              />
            </Stack>
          </FormBlock>

          {/* Talent */}
          <FormBlock title={<FormattedMessage id={`${localizationSubset}.talentConfiguration.sectTitle`} />}>
            <Grid container spacing={2} sx={{ padding: '16px' }}>
              <Grid item xs={12}>
                <FormRadioGroup
                  name="staffingType"
                  label={<FormattedMessage id={`${localizationSubset}.talentConfiguration.talentType.label`} />}
                  items={[
                    {
                      label: <FormattedMessage id={`${localizationSubset}.talentConfiguration.talentType.items.all`} />,
                      value: 'All',
                    },
                    {
                      label: (
                        <FormattedMessage id={`${localizationSubset}.talentConfiguration.talentType.items.internal`} />
                      ),
                      value: 'Internal',
                    },
                    {
                      label: (
                        <FormattedMessage id={`${localizationSubset}.talentConfiguration.talentType.items.external`} />
                      ),
                      value: 'External',
                    },
                  ]}
                  required
                  disabled={loading}
                  row
                />
              </Grid>
              <Grid item xs={12}>
                <QualificationSelect fullWidth name="qualifications" required localizationSubset={localizationSubset} />
              </Grid>
              <Grid item xs={12}>
                <AssigneesSelect fullWidth name="assignees" localizationSubset={localizationSubset} />
              </Grid>
            </Grid>
          </FormBlock>

          {/* Additional info */}
          <FormBlock title={<FormattedMessage id={`${localizationSubset}.additionalInfo.sectTitle`} />}>
            <Grid container spacing={2}>
              <Grid item xs={6}>
                <FormTextField
                  disabled={loading}
                  label={<FormattedMessage id={`${localizationSubset}.additionalInfo.tag.label`} />}
                  placeholder={intl.formatMessage({
                    id: `${localizationSubset}.additionalInfo.tag.placeholder`,
                  })}
                  fullWidth
                  name="tags"
                />
              </Grid>
            </Grid>
          </FormBlock>
          <CollapsibleFormBlock
            sectTitle={<FormattedMessage id={`${localizationSubset}.additionalInfo.notes.sectTitle`} />}
          >
            <FormTextField
              name="notes"
              fullWidth
              InputProps={{
                sx: { height: '150px' },
              }}
            />
          </CollapsibleFormBlock>
        </Form>
      </Stack>
    </ClosableDrawer>
  );
}

type TimeRangeRecord = {
  start: Dayjs | null;
  end: Dayjs | null;
};

type RotationSequenceSectionProps = {
  localizationSubset: string;
  onChange: (sequence: SequenceItem[]) => void;
};

function RotationSequenceSection({ localizationSubset, onChange }: RotationSequenceSectionProps) {
  const intl = useIntl();
  const [sequence, setSequence] = useState<SequenceItem[]>([{ isPause: false, durationInDays: 1, order: 0 }]);

  useEffect(() => {
    onChange && onChange(sequence.map((seq, idx) => ({ ...seq, order: idx })));
  }, [sequence]);

  const handleToggleChange = (value: boolean, index: number) => {
    const newVer = [...sequence];
    newVer[index] = { ...newVer[index], isPause: value };
    setSequence(newVer);
  };

  const handleDaysChange = (value: number, index: number) => {
    const newVer = [...sequence];
    newVer[index] = { ...newVer[index], durationInDays: value };
    setSequence(newVer);
  };

  const handleAdd = (index: number) => {
    const newVer = [...sequence];
    newVer.splice(index, 0, { order: index, isPause: false, durationInDays: 0 });
    setSequence(newVer);
  };
  const handleCopy = (index: number) => {
    const newVer = [...sequence];
    newVer.splice(index, 0, newVer[index]);
    setSequence(newVer);
  };

  return (
    <Stack direction={'column'} spacing={2}>
      {sequence.map((seq, idx) => (
        <Grid container key={idx}>
          <Grid item xs={6}>
            <FormControlLabel
              checked={!seq.isPause}
              onChange={(evt: SyntheticEvent<Element, Event>, checked: boolean) => handleToggleChange(!checked, idx)}
              control={<Switch />}
              label={intl.formatMessage({ id: `${localizationSubset}.rotationSequence.workingDayToggle.label` })}
            />
          </Grid>
          <Grid item xs={6}>
            <Stack direction={'row'} sx={{ width: '100%' }}>
              <TextField
                value={seq.durationInDays}
                InputProps={{
                  type: 'number',
                }}
                onChange={(event) => {
                  +event.target.value < 0 && (event.target.value = '' + 0);
                  event.target.value = '' + parseInt(event.target.value, 10);
                  handleDaysChange(+event.target.value, idx);
                }}
              />
              <IconButton size="small" onClick={() => handleAdd(idx)}>
                <AddIcon sx={{ width: '24px', height: '24px' }} />
              </IconButton>
              <IconButton size="small" onClick={() => handleCopy(idx)}>
                <ContentCopyIcon sx={{ width: '18px', height: '18px' }} />
              </IconButton>
            </Stack>
          </Grid>
        </Grid>
      ))}
    </Stack>
  );
}

const MenuButton = styled(Button)(({ theme }) => ({
  minWidth: theme.spacing(16.75),
}));

type DayMark = DayOfWeekFull;

type ShiftRotationFormData = {
  template: string;
  numberOfCopies: number;
  isUrgent: boolean;

  durationType: 'setEndDate' | 'infiniteLoop';
  startDate: Dayjs | null;
  endDate: Dayjs | null;

  seriesPreset: string;
  shiftTypePreset: string;
  startTime: Dayjs | null;
  endTime: Dayjs | null;

  location: string;
  department: string;
  subDepartment: string;
  speciality: string;

  staffingType: SingleShift['staffingType'];
  qualifications: string[];
  assignees: string[];

  tags: string;
  notes: string;
};

function calcTimeSlots(
  startDate?: Dayjs | null,
  endDate?: Dayjs | null,
  startTime?: Dayjs | null,
  endTime?: Dayjs | null,
  sequence: SequenceItem[] = [],
) {
  if (!startDate || !endDate || !startTime || !endTime) {
    return [];
  }
  const slotsDays = [];
  let currentDate = startDate
    .clone()
    .set('hour', startTime?.hour() || 0)
    .set('minute', startTime?.minute() || 0);
  const seqMask = sequence.flatMap(({ isPause, durationInDays }) =>
    [...Array(durationInDays).keys()].map(() => (isPause ? 0 : 1)),
  );
  if (seqMask.length === 0) {
    return [];
  }
  let seqIndex = 0;

  while (!currentDate.isAfter(endDate)) {
    if (seqIndex >= seqMask.length) {
      seqIndex = 0;
    }

    if (seqMask[seqIndex] === 1) {
      slotsDays.push(currentDate.clone());
    }
    seqIndex++;
    currentDate = currentDate.add(1, 'day');
  }

  return slotsDays.map((d) => ({
    start: d,
    end: d
      .clone()
      // Add 1 day if end time is before start time (on the next day)
      .add(startTime.isAfter(endTime) ? 1 : 0, 'day')
      .set('hour', endTime.hour() || 0)
      .set('minute', endTime.minute() || 0),
  }));
}
