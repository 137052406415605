import { FormattedMessage, useIntl } from 'react-intl';
import Typography from '@mui/material/Typography';
import { dateRangeShortcuts } from '@libs/models/shifts';
import { Filter, isDateRangeFilter } from '@libs/models/common/filter';
import { useShiftsFilter } from '@libs/store/shifts';
import { DateRangeSelector } from '@atoms/inputs';
import { Flex } from '@atoms/layout';
import { Dayjs } from 'dayjs';

export function StartDateRangeFilterInput() {
  const intl = useIntl();
  const { activeFilters, setFilter, clearFilter } = useShiftsFilter();
  const startDateFilter = activeFilters.find((f) => f.key === 'startDate');
  const startDate = startDateFilter && isDateRangeFilter(startDateFilter) ? startDateFilter.value : undefined;
  const dateLabel = [startDate?.from?.format('MMM D, YYYY'), startDate?.to?.format('MMM D, YYYY')]
    .filter(Boolean)
    .join(' - ');

  return (
    <StartDateRangeFilterInputControlled
      dateLabel={dateLabel}
      dateValue={startDate}
      onClear={() => clearFilter('startDate')}
      onSetFilter={(filter) =>
        setFilter({
          ...filter,
          key: 'startDate',
        })
      }
    />
  );
}

type StartDateRangeFilterInputControlledProps = {
  onClear: () => void;
  onSetFilter: (filter: Filter) => void;
  dateLabel: string;
  dateValue:
    | {
        from: Dayjs;
        to: Dayjs;
      }
    | undefined;
  disabled?: boolean;
};
export function StartDateRangeFilterInputControlled({
  onClear,
  onSetFilter,
  dateLabel,
  dateValue,
  disabled = false,
}: StartDateRangeFilterInputControlledProps) {
  const intl = useIntl();

  return (
    <DateRangeSelector
      onAccept={(v) => {
        if (!v[0] || !v[1]) {
          onClear && onClear();
          return;
        }
        onSetFilter({
          key: '',
          value: {
            from: v[0],
            to: v[1],
          },
        });
      }}
      disabled={disabled}
      fullWidth
      label={
        <Flex>
          <Typography variant="body1" color="text.secondary" sx={{ flexShrink: 0 }}>
            <FormattedMessage id="shifts.fields.startDate" />
            &nbsp;
          </Typography>
          <Typography variant="body1" color="text.primary" noWrap title={dateLabel}>
            {dateLabel || <FormattedMessage id="shifts.startDateAll" />}
          </Typography>
        </Flex>
      }
      shortcutsItems={dateRangeShortcuts.map(({ labelKey, ...sh }) => ({
        ...sh,
        label: intl.formatMessage({ id: labelKey }),
      }))}
      value={[dateValue?.from ?? null, dateValue?.to ?? null]}
    />
  );
}
