import {
  Column,
  DateHelper,
  PresetManager,
  ProjectModelConfig,
  ResourceModel,
  SchedulerResourceModel,
} from '@bryntum/schedulerpro';
import { BryntumGridProps, BryntumSchedulerProProps } from '@bryntum/schedulerpro-react';
import { Shift } from './scheduler/lib/Shift';
import { Doctor } from './scheduler/lib/Doctor';
import { CalendarItem } from './components/calendarItem';
import { ResourceItem } from './components/resourceItem';

export const projectConfig: ProjectModelConfig = {
  resourceStore: {
    modelClass: Doctor,
    sorters: [{ field: 'name', ascending: true }],
  },

  // events: data.events.rows,
  eventStore: {
    // Unassigned events should remain in store
    removeUnassignedEvent: false,
    modelClass: Shift,
  },

  calendars: [
    {
      id: 'workweek',
      name: 'Work week',
      intervals: [
        {
          recurrentStartDate: 'on Sat',
          recurrentEndDate: 'on Mon',
          isWorking: false,
        },
      ],
    },
    {
      id: 'allday',
      name: 'All day',
      unspecifiedTimeIsWorking: false,
      intervals: [
        {
          recurrentStartDate: 'every weekday at 01:00',
          recurrentEndDate: 'every weekday at 23:59',
          isWorking: true,
        },
      ],
    },
  ],
  calendar: 'dayAndWeek',

  assignments: [],
  dependencies: [],
};

PresetManager.registerPreset('myDayWeek', {
  id: 'myDayWeek',
  base: 'dayAndWeek',
  shiftUnit: 'week',
  timeResolution: {
    increment: 1,
    unit: 'd',
  },
  headers: [
    {
      unit: 'd',
      align: 'center',
      dateFormat: 'ddd DD',
    },
  ],
});

export const schedulerConfig: BryntumSchedulerProProps = {
  // startDate: DateHelper.startOf(new Date(), 'week', undefined, 1),
  // endDate: new Date(2024, 12, 30),
  rowHeight: 64,
  barMargin: 10,
  tickSize: 150,
  fillTicks: true,
  zoomOnTimeAxisDoubleClick: false,
  eventStyle: 'border',
  eventColor: 'blue',
  allowOverlap: true,
  useInitialAnimation: false,
  // resourceImagePath: './users',
  createEventOnDblClick: false,

  columns: [
    {
      type: 'template',
      field: 'name',
      text: 'Talents',
      width: 220,
      sortable(userLeft, userRight) {
        const user1 = userLeft as Doctor;
        const user2 = userRight as Doctor;
        const user1Constraint = user1.firstName || user1.lastName;
        const user2Constraint = user2.firstName || user2.lastName;
        return user1Constraint < user2Constraint ? -1 : 1;
      },
      // showEventCount: false,
      // showMeta: (resourceRecord) => {
      //   const doctor = resourceRecord as Doctor;
      //   return `<i class="${doctor.roleIconCls}"></i>${doctor.role}`;
      // },
      template: (props) => {
        const talent = props.record as Doctor;
        return <ResourceItem data={talent} hoursScheduled={talent.getBookedHours()} />;
      },
    },
  ],

  zoomOnMouseWheel: false,

  viewPreset: {
    id: 'myDayWeek',
    base: 'dayAndWeek',
    shiftUnit: 'week',
    timeResolution: {
      increment: 1,
      unit: 'd',
    },
    headers: [
      {
        unit: 'd',
        align: 'center',
        dateFormat: 'ddd DD',
      },
    ],
  },
  presets: [
    {
      id: 'myDayWeek',
      base: 'dayAndWeek',
      shiftUnit: 'week',
      timeResolution: {
        increment: 1,
        unit: 'd',
      },
      headers: [
        {
          unit: 'd',
          align: 'center',
          dateFormat: 'ddd DD',
        },
      ],
    },
  ],

  scheduleMenuFeature: {
    disabled: true,
  },
  showTooltip: false,
  cellTooltipFeature: false,
  labelsFeature: { disable: true },
  timeAxisHeaderMenuFeature: {
    disabled: true,
  },
  cellMenuFeature: {
    disabled: true,
  },
  stickyEventsFeature: {
    disabled: true,
  },
  dependenciesFeature: {
    disabled: true,
  },
  eventResizeFeature: {
    disabled: true,
  },
  eventTooltipFeature: {
    disabled: true,
    // template: data =>``
  },

  timeRangesFeature: {
    showHeaderElements: true,
    // showCurrentTimeLine: true,
  },
  groupFeature: {
    field: 'staffingType',
    disabled: false,
    headerHeight: 48,
    renderer({ groupRowFor, column }: { groupRowFor: string; column: Column }) {
      if (column.parentIndex === 0) {
        return `${groupRowFor}`;
      }
      return '';
    },
  },

  stripeFeature: false,
  timeSpanHighlightFeature: true,
  getDateConstraints: (resourceRecord, eventRecord) => {
    return {
      start: new Date(eventRecord?.startDate || Date.now()),
      end: new Date(eventRecord?.startDate || Date.now()),
    };
  },
  onEventDragAbort(event) {
    (event.source as any).unhighlightTimeSpans();
  },
  filterBarFeature: {
    compactMode: true,
  },
  calendarHighlightFeature: {
    calendar: 'resource',
    // This method is provided to determine which resources are available for one or more eventRecords,
    // in order to highlight the right availability intervals
    collectAvailableResources({ scheduler, eventRecords }) {
      const shift = eventRecords[0] as Shift;
      return scheduler.resourceStore.query((talent: Doctor) => {
        const talentQuals = talent.nurseQualifications.map((qual) => qual.id);
        const reqQuals = shift.nurseQualifications.map((qual) => qual.id);
        return reqQuals.some((qual) => talentQuals.includes(qual));
      }) as ResourceModel[];
    },
  },
  overlappingEventSorter: (a, b) => {
    return new Date((a as Shift).actualStartDate).getTime() - new Date((b as Shift).actualStartDate).getTime();
  },

  eventEditFeature: {
    disabled: true,
  },
  eventCopyPasteFeature: {
    disabled: true,
  },
  eventDragCreateFeature: {
    disabled: true,
  },
  cellEditFeature: {
    disabled: true,
  },
  eventDragFeature: {
    disabled: true,
    constrainDragToTimeSlot: false,
    constrainDragToTimeline: true,

    // validatorFn(params) {
    //   const { eventRecords, newResource, startDate, endDate } = params;
    //   const task = eventRecords[0] as Shift;
    //   const doctor = newResource as Doctor;
    //   const { calendar } = doctor;
    //   const valid =
    //       doctor.role === task.requiredRole &&
    //       (!calendar || (calendar as CalendarModel).isWorkingTime(startDate, endDate)),
    //     message = valid ? '' : 'No available slot';
    //   return {
    //     valid,
    //     message: (valid ? '' : '<i class="b-icon b-fa-exclamation-triangle"></i>') + message,
    //   };
    // },
  },
  taskEditFeature: {
    disabled: true,
    // editorConfig: {
    //   title: 'Shift',
    // },

    // // Customize its contents inside the General tab
    // items: {
    //   generalTab: {
    //     // Add a patient field
    //     items: {
    //       // Add a patient field
    //       orderField: {
    //         type: 'text',
    //         name: 'patient',
    //         label: 'Patient',
    //         // Place after name field
    //         weight: 150,
    //       },
    //     },
    //   },
    // },
  },
  eventLayout: {
    type: 'stack',
  },
};

// Custom grid that holds unassigned appointments
export const gridConfig: BryntumGridProps = {
  selectionMode: {
    cell: false,
    // multiSelect : false,
  },
  rowHeight: 64,
  stripeFeature: true,
  sortFeature: 'startDate',
  filterFeature: true,
  cellMenuFeature: {
    disabled: true,
  },
  filterBarFeature: {
    compactMode: true,
  },
  groupFeature: {
    field: 'grouping',
    // headerHeight: 20,
    renderer({ groupRowFor, column }: { groupRowFor: Date; column: Column }) {
      if (column.parentIndex === 0) {
        return `${DateHelper.format(new Date(groupRowFor), 'MMM D, YYYY')}`;
      }
      return '';
    },
  },
  cellEditFeature: {
    disabled: true,
  },
  columns: [
    {
      type: 'template',
      field: 'name',
      text: 'Open Shifts',
      flex: 1,
      autoHeight: true,
      cellCls: 'unscheduledNameCell',
      template: ({ record }) => {
        return <CalendarItem data={record as Shift} mode="grid" />;
      },
    },
  ],
  disableGridRowModelWarning: true,
};
