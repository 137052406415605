import { create, StateCreator } from 'zustand';
import { AxiosError, isAxiosError } from 'axios';
import dayjs from 'dayjs';
import {
  activateTalents,
  createInternalTalent,
  deactivateTalents,
  editTalent,
  getTalent,
  getTalents,
  type GetTalentsParams,
} from '@libs/api/talents';
import { TalentData, TalentListItem } from '@libs/models/talents';
import { ErrorWithDetails } from '@libs/utils';
import { StaffHasActiveShiftsError, StaffHasActiveShiftsErrorDetails } from '@libs/models/talents/talent';

type TalentsListState = {
  loading: boolean;
  initiated: boolean;
  page: number;
  total: number;
  perPage: number;
  load: (params?: GetTalentsParams) => Promise<void>;
  talents: TalentListItem[];
  selectedTalents: string[];
  setSelectedTalents: (talents: string[]) => void;
  deactivateTalents: (ids: string[]) => ReturnType<typeof deactivateTalents>;
  activateTalents: (ids: string[]) => Promise<void>;
};

const createTalentsListSlice: StateCreator<TalentsListState, [], [], TalentsListState> = (set, get) => ({
  loading: false,
  initiated: false,
  load: async (params) => {
    set({
      loading: true,
    });
    const state = get();
    try {
      const { data } = await getTalents({
        page: state.page,
        size: state.perPage,
        ...params,
      });
      set({
        talents: data.items.map((t) => ({
          ...t,
          lastWorkedTime: dayjs(t.lastWorkedTime),
        })),
        total: data.totalCount,
        page: data?.pageNumber ?? 1,
        perPage: data?.pageSize ?? 10,
        initiated: true,
        loading: false,
      });
    } finally {
      set({
        loading: false,
      });
    }
  },
  page: 1,
  perPage: 10,
  total: 0,
  talents: [],
  selectedTalents: [],
  setSelectedTalents: (talents) => {
    set({ selectedTalents: talents });
  },
  deactivateTalents: async (ids: string[]) => {
    const state = get();
    try {
      const result = await deactivateTalents(ids);
      const notDeactivated = result.data.errors?.staffHasActiveShifts.map(({ staffId }) => staffId) ?? [];

      set({
        talents: state.talents.map((t) => {
          if (ids.includes(t.id) && !notDeactivated.includes(t.id)) {
            return { ...t, staffingStatus: 'Deactivated' };
          }
          return t;
        }),
      });

      return result;
    } catch (error) {
      if (isAxiosError(error)) {
        if (isStaffHasActiveShiftsError(error) && error.response) {
          throw new StaffHasActiveShiftsError(error.response.data);
        }
        if (typeof error.response?.data === 'object') {
          throw new ErrorWithDetails(error.response?.data.detail, { type: error.response?.data.status, values: {} });
        }
      }
      throw error;
    }
  },
  activateTalents: async (ids: string[]) => {
    const state = get();
    await activateTalents(ids);
    // TODO: reload the list to get actual status
    set({
      talents: state.talents.map((t) => {
        if (ids.includes(t.id)) {
          return { ...t, staffingStatus: 'Active' };
        }
        return t;
      }),
    });
  },
});

type TalentsEditState = {
  createTalent: (data: Omit<TalentData, 'status'>) => Promise<void>;
  saveTalent: (id: string, data: Omit<TalentData, 'status'>) => Promise<void>;
  saving: boolean;
  showEditForm: boolean;
  showCreateForm: boolean;
  openCreateForm: () => void;
  openEditForm: (id: string) => Promise<void>;
  editInitial: (TalentData & { id: string }) | null;
  editLoading: boolean;
  closeEditForm: () => void;
};
const createTalentsEditSlice: StateCreator<TalentsListState & TalentsEditState, [], [], TalentsEditState> = (
  set,
  get,
) => ({
  saving: false,
  editInitial: null,
  editLoading: false,
  createTalent: async (data) => {
    set({ saving: true });
    try {
      await createInternalTalent({
        ...data,
        qualificationPayRate: data.qualificationPayRates[0],
      });
      const state = get();
      await state.load({
        page: 1,
      });
    } catch (error) {
      if (isAxiosError(error)) {
        throw error.response?.data;
      }
      throw error;
    } finally {
      set({ saving: false });
    }
  },
  saveTalent: async (id, data) => {
    set({ saving: true });
    try {
      await editTalent(id, {
        ...data,
        qualificationPayRate: data.qualificationPayRates[0],
      });
      const state = get();
      await state.load({
        page: 1,
      });
    } catch (error) {
      if (isAxiosError(error)) {
        throw error.response?.data;
      }
      throw error;
    } finally {
      set({ saving: false });
    }
  },
  showCreateForm: false,
  showEditForm: false,
  openCreateForm: () => {
    set({ showCreateForm: true });
  },
  closeEditForm: () => {
    set({ showEditForm: false, showCreateForm: false, editLoading: false, editInitial: null });
  },
  openEditForm: async (id) => {
    set({ showEditForm: true, editLoading: true });
    const response = await getTalent(id);
    const talent = response.data;
    set({
      editLoading: false,
      editInitial: {
        ...talent,
        status: talent.staffingStatus,
        // Set image of type `File` to null fo initial data (it is user to post new image)
        // get image from the `imageUrl` field
        image: null,
        isImageUrlNeedToBeUpdated: false,
        qualificationPayRates: talent.qualificationPayRates.map((q) => ({
          ...q,
          id: q.qualification.id,
          specialtyPayRates: q.specialties.map((sp) => ({ id: sp.specialty.id, rate: sp.rate })),
        })),
        locationId: talent.location?.id ?? '',
      },
    });
  },
});

export const useTalentsStore = create<TalentsListState & TalentsEditState>()((...a) => ({
  ...createTalentsListSlice(...a),
  ...createTalentsEditSlice(...a),
}));

function isStaffHasActiveShiftsError(error: unknown): error is AxiosError<StaffHasActiveShiftsErrorDetails> {
  return (
    isAxiosError(error) && (error.response?.data as StaffHasActiveShiftsErrorDetails)?.type === 'staffHasActiveShifts'
  );
}
