import { ComponentProps, useMemo } from 'react';
import { FormattedDate, FormattedMessage, IntlShape, useIntl } from 'react-intl';
import dayjs from 'dayjs';
import { GridColDef } from '@mui/x-data-grid';
import Typography from '@mui/material/Typography';
import IconButton from '@mui/material/IconButton';
import Chip from '@mui/material/Chip';
import { Flex } from '@atoms/layout';
import { FaIcon } from '@atoms/icons';
import { LightChip, ShiftRecurrenceChip, TooltipNote } from '@atoms/dataDisplay';
import { isDateSeparator } from '@molecules/dataDisplay';
import { DateSeparator } from '@molecules/dataDisplay';
import { ShiftType } from '../shiftType';
import { AssigneeColumn } from './assigneeColumn';
import { Rotation, ShiftOld } from '@libs/models/shifts/shift';
import { DateHelper } from '@bryntum/schedulerpro';
import { Tooltip } from '@mui/material';

export function useShiftListColumns() {
  const intl = useIntl();

  // Columns should be memoized to prevent table flickering and state loss
  return useMemo(() => {
    const columns: GridColDef<ShiftOld | DateSeparator>[] = [
      getShiftStartColumn(intl, 'shifts.fields.shiftType'),
      getRecurrenceColumn(intl, 'shifts.fields.recurrence'),
      getQualificationsColumn(intl),
      getSpecialtyColumn(intl),
      getStatusColumn(intl),
      getNotesColumn(intl),
      getAssigneeColumn(intl),
    ];
    return columns;
  }, [intl]);
}

export function useRotationsListColumns() {
  const intl = useIntl();

  // Columns should be memoized to prevent table flickering and state loss
  return useMemo(() => {
    const columns: GridColDef<Rotation | ShiftOld | DateSeparator>[] = [
      getRotationDatesColumn(intl),
      getRecurrenceColumn(intl, 'shifts.fields.shiftType'),
      getRotationDurationColumn(intl),
      getQualificationsColumn(intl),
      getSpecialtyColumn(intl),
      getStatusColumn(intl),
      getNotesColumn(intl),
      getAssigneeColumn(intl),
    ];
    return columns;
  }, [intl]);
}

function getShiftStartColumn(
  intl: IntlShape,
  labelId: string,
): GridColDef<ShiftOld | DateSeparator, ShiftOld['shiftStart']> {
  return {
    field: 'shiftStart',
    headerName: intl.formatMessage({
      id: labelId,
    }),
    sortComparator: (v1, v2, param1, param2) => {
      if (!v1) {
        return 0;
      }
      if (!v2) {
        return 1;
      }
      return `${v1.toISOString()}`.localeCompare(`${v1.toISOString()}`);
    },
    width: 160,
    colSpan: (_, row) => {
      if (isDateSeparator(row)) {
        return 8;
      }
      return 1;
    },
    renderCell: (params) => {
      if (isDateSeparator(params.row)) {
        return (
          <Flex alignCenter height="100%" px={0.75}>
            <Typography variant="subtitle1">
              <FormattedDate value={params.row.date} dateStyle="full" />
            </Typography>
          </Flex>
        );
      }

      return (
        <ShiftType
          startTime={dayjs(params.row.shiftStart).tz(params.row.timeZone)}
          endTime={dayjs(params.row.shiftEnd).tz(params.row.timeZone)}
          boxProps={{ height: '100%' }}
        />
      );
    },
  };
}

function getRotationDatesColumn(
  intl: IntlShape,
): GridColDef<Rotation | ShiftOld | DateSeparator, ShiftOld['shiftStart']> {
  return {
    field: 'shiftStart',
    headerName: intl.formatMessage({
      id: 'shifts.fields.date',
    }),
    sortable: true,
    sortComparator: (v1, v2, param1, param2) => {
      if (!v1) {
        return 0;
      }
      if (!v2) {
        return 1;
      }
      return `${v1.toISOString()}`.localeCompare(`${v1.toISOString()}`);
    },
    width: 160,
    renderCell: (params) => {
      if (!params.row) {
        return null;
      }
      const row = params.row as Rotation;
      const formattedStart = DateHelper.format(row.shiftStart, 'MM.DD');
      const formattedEnd = DateHelper.format(DateHelper.add(row.shiftStart, row.shiftLength), 'MM.DD.YYYY');
      return `${formattedStart} - ${formattedEnd}`;
    },
  };
}

function getRotationDurationColumn(intl: IntlShape): GridColDef<ShiftOld | DateSeparator, ShiftOld['recurrence']> {
  return {
    field: 'duration',
    headerName: intl.formatMessage({
      id: 'shifts.fields.duration',
    }),
    width: 115,
    renderCell: (params) => {
      if (!params.value) {
        return null;
      }

      return DateHelper.formatDelta(+params.value);
    },
  };
}

function getRecurrenceColumn(
  intl: IntlShape,
  labelId: string,
): GridColDef<ShiftOld | Rotation | DateSeparator, ShiftOld['recurrence']> {
  return {
    field: 'recurrence',
    headerName: intl.formatMessage({
      id: labelId,
    }),
    sortable: true,
    width: 115,
    renderCell: (params) => {
      if (!params.value) {
        return null;
      }

      return (
        <ShiftRecurrenceChip
          variant="outlined"
          type={params.value}
          label={<FormattedMessage id={`shifts.recurrence.${params.value}`} />}
          size="small"
        />
      );
    },
  };
}

function getQualificationsColumn(intl: IntlShape): GridColDef<ShiftOld | DateSeparator, ShiftOld['qualifications']> {
  return {
    field: 'qualifications',
    headerName: intl.formatMessage({
      id: 'shifts.fields.qualifications',
    }),
    width: 140,
    sortable: true,
    sortComparator: (v1, v2) => {
      if (!v1) {
        return 0;
      }
      if (!v2) {
        return 1;
      }
      return `${v1[0].abbreviation}`.localeCompare(`${v2[0].abbreviation}`);
    },
    renderCell: (params) => {
      if (!params.value) {
        return null;
      }
      return params.value.map((qual) => (
        <Tooltip key={qual.id} title={qual.name || ''}>
          <Chip variant="filled" label={qual.abbreviation} size="small" />
        </Tooltip>
      ));
    },
  };
}

function getSpecialtyColumn(intl: IntlShape): GridColDef<ShiftOld | DateSeparator, ShiftOld['specialty']> {
  return {
    field: 'specialty',
    flex: 1,
    headerName: intl.formatMessage({
      id: 'shifts.fields.specialty',
    }),
    sortable: true,
    sortComparator: (v1, v2, param1, param2) => {
      if (!v1) {
        return 0;
      }
      if (!v2) {
        return 1;
      }
      return `${v1.name}`.localeCompare(`${v1.name}`);
    },
    width: 170,
    renderCell: (params) => {
      if (!params.value) {
        return null;
      }
      return <Chip variant="filled" label={params.value.name} size="small" />;
    },
  };
}

function getStatusColumn(intl: IntlShape): GridColDef<ShiftOld | DateSeparator, ShiftOld['status']> {
  return {
    field: 'status',
    headerName: intl.formatMessage({ id: 'shifts.fields.status' }),
    width: 120,
    sortable: true,
    renderCell: (params) => {
      if (!params.value) {
        return null;
      }
      const colorByStatus: Record<ShiftOld['status'], ComponentProps<typeof LightChip>['color']> = {
        assigned: 'success',
        reAssigned: 'success',
        pending: 'warning',
        inProgress: 'primary',
        completed: 'default',
        canceled: 'error',
        na: 'default',
      };

      return (
        <Chip
          variant="filled"
          disabled={params.value === 'na'}
          color={colorByStatus[params.value]}
          label={<FormattedMessage id={`shifts.status.${params.value}`} />}
          size="small"
        />
      );
    },
  };
}

function getNotesColumn(intl: IntlShape): GridColDef<ShiftOld | DateSeparator, ShiftOld['notes']> {
  return {
    field: 'notes',
    headerName: intl.formatMessage({ id: 'shifts.fields.notes' }),
    width: 50,
    renderCell: (params) => {
      const isEmpty = !params.value || params.value.trim() === '';
      return (
        <TooltipNote
          title={
            <Typography variant="body2">
              {!isEmpty ? params.value : <FormattedMessage id="shifts.emptyNote" />}
            </Typography>
          }
          popoverTooltipSx={{
            py: isEmpty ? 1 : 2,
            px: isEmpty ? 3.5 : 2,
            color: isEmpty ? 'text.secondary' : 'inherit',
          }}
          placement="bottom-end"
        >
          {/* Span needed to be able to use Tooltip on disabled element */}
          <span>
            <IconButton size="small" disabled={!params.value}>
              <FaIcon name="memo-pad" color="inherit" />
            </IconButton>
          </span>
        </TooltipNote>
      );
    },
  };
}

function getAssigneeColumn(intl: IntlShape): GridColDef<ShiftOld | DateSeparator, ShiftOld['assignee']> {
  return {
    field: 'assignee',
    headerName: intl.formatMessage({ id: 'shifts.fields.assignee' }),
    flex: 1,
    // To prevent assignee column component to collapse
    minWidth: 165,
    sortable: true,
    sortComparator: (v1, v2) => {
      if (!v1) {
        return 0;
      }
      if (!v2) {
        return 1;
      }
      return `${v1.name}`.localeCompare(`${v1.name}`);
    },
    renderCell: (params) => {
      if (!isDateSeparator(params.row)) {
        return <AssigneeColumn shift={params.row} />;
      }
    },
  };
}
