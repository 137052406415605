import { Dayjs } from 'dayjs';
import { Qualification, Specialty } from '../settings';
import { QualificationShort } from '../settings/organization';

export const SHIFT_STATUSES = {
  OPENED: 'Opened',
  PENDING: 'Pending',
  ASSIGNED: 'Assigned',
  REASSIGNED: 'Reassigned',
  CANCELED: 'Canceled',
  IN_PROGRESS: 'InProgress',
  UNFILLED: 'Unfilled',
  DELETED: 'Deleted',
  REOPENED: 'Reopened',
  EXPIRED: 'Expired',
  UNASSIGNED: 'Unassigned',
  COMPLETED: 'Completed',
  NO_SHOW: 'NoShow',
} as const;

export const SHIFT_STATUS_LIST = Object.values(SHIFT_STATUSES);

type ShiftStatusesKey = keyof typeof SHIFT_STATUSES;
export type ShiftStatus = (typeof SHIFT_STATUSES)[ShiftStatusesKey];

type ShiftBase = {
  id: string;
  startDate: string; // ISO stringified Date
  endDate: string; // ISO stringified Date
  timeZone: string;
  status: ShiftStatus;
  isUrgent: boolean;
};

export type ShiftList = ShiftBase & {
  nurseQualifications: QualificationShort[];
  otherQualifications: QualificationShort[];
  staff: {
    id: string;
  };
  recurrence: string;
};

export type Shift = ShiftBase & {
  id: string;
  internalId: string;
  staffNurseQualificationFilters: Qualification[];
  staffOtherQualificationFilters: Qualification[];
  staff: ShiftAssignee;
  startDate: string;
  endDate: string;
  timeZone: string;
  staffingType: StaffingType;
  location: {
    id: string;
    label: string;
  };
  department: {
    id: string;
    label: string;
  };
  subDepartment: {
    id: string;
    label: string;
  };
  speciality: {
    id: string;
    title: string;
    category: string;
  };
  tags: string[];
  note: string;
  isUrgent: boolean;
  status:
    | 'Opened'
    | 'Reopened'
    | 'Assigned'
    | 'Unassigned'
    | 'Pending'
    | 'NoShow'
    | 'InProgress'
    | 'Completed'
    | 'Canceled';
};

type ShiftAssignee = {
  id: string;
  firstName: string;
  lastName: string;
  imageURL: string;
  specialties: Specialty[];
  nurseQualifications: Qualification[];
};

export type ShiftType = 'day' | 'eve' | 'noc';

export type StaffingType = 'All' | 'Internal' | 'External';

export type SingleShift = {
  numberOfCopies: number;
  assignees: string[];
  staffingType: StaffingType;
  startDate: Dayjs;
  durationInSeconds: number;
  timeZone: string;
  staffNurseQualificationFilterIds: string[];
  staffOtherQualificationFilterIds: string[];
  departmentNodeId: string | null;
  subDepartmentNodeId: string | null;
  specialityId: string | null;
  locationId: string;
  tags: string[];
  note: string;
  isUrgent: boolean;
  isNeedToPublish: boolean;
};

// Shifts starting between 4am and 11:59AM are categorized as day shifts.
// Shifts starting between 12pm and 7:59pm are designated as evening shifts.
// Shifts starting between 8pm and 3:59am are classified as night shifts.
export function getShiftType(date: Dayjs): ShiftType {
  if (date.hour() >= 4 && date.hour() < 12) {
    return 'day';
  }
  if (date.hour() >= 12 && date.hour() < 20) {
    return 'eve';
  }

  return 'noc';
}

export function getTimeByShiftType(shift: string): { startTime: string; duration: string } {
  const shiftType = shift.slice(0, 3);
  const duration = parseInt(shift.slice(3, 5), 10);
  const typeToTime: Record<string, string> = {
    day: '04:00:00',
    eve: '12:00:00',
    noc: '20:00:00',
  };

  return {
    startTime: typeToTime[shiftType],
    duration: `${duration.toString().padStart(2, '0')}:00:00`,
  };
}
export function getShiftTypeByTimeAndDuration(startTime: string, duration: string): string {
  const startHour = parseInt(startTime.slice(0, 2), 10) ?? 0;
  const durationHour = parseInt(duration.slice(0, 2), 10) ?? 0;
  const type = startHour <= 4 ? 'noc' : startHour <= 12 ? 'day' : startHour <= 20 ? 'eve' : 'noc';
  const durationStr = durationHour < 12 ? '8h' : '12h';

  return `${type}${durationStr}`;
}
export function getShiftTypeByTimeRange(startDate: Dayjs, endDate: Dayjs) {
  const startHour = startDate.hour();
  const durationHour = endDate.diff(startDate, 'hours');
  const type = startHour <= 4 ? 'noc' : startHour <= 12 ? 'day' : startHour <= 20 ? 'eve' : 'noc';
  const durationStr = durationHour < 12 ? '8h' : '12h';

  return {
    type,
    durationStr,
    durationHour,
  };
}

export type ShiftOld = {
  id: string;
  shiftStart: Date;
  shiftLength: number;
  shiftEnd: Date;
  // TODO: check Intl when real statuses are available
  recurrence: 'single' | 'series' | 'rotation';
  location?: {
    name: string;
    address1: string;
    address2?: string;
    city: string;
    state: string;
  };
  qualifications: Qualification[];
  specialty: Specialty | null;
  // TODO: check Intl when real statuses are available
  status: 'assigned' | 'reAssigned' | 'pending' | 'inProgress' | 'completed' | 'canceled' | 'na';
  notes?: string;
  assignee: AssigneeOld | null;
  locationDetails?: {
    department?: string;
    subDepartment?: string;
    primaryPhoneNumber: string;
    secondaryPhoneNumber: string;
  };
  mainScheduler?: {
    name: string;
    jobTitle: string;
    primaryPhoneNumber: string;
    email: string;
  };
  afterHoursScheduler?: {
    name: string;
    jobTitle: string;
    primaryPhoneNumber: string;
    email: string;
  };
  timeZone: string;
};

export type Rotation = ShiftOld & {
  duration: number;
};

type AssigneeOld = {
  // TODO: check rendering when real avatars are available
  avatar: string;
  name: string;
  assignedByClient: boolean;
  specialty?: string;
};
