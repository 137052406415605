import { create } from 'zustand';
import { assignShift, cancelSingleShift, unassignShift, deleteSingleShift, shareShifts } from '@libs/api/shifts';
import { Shift } from '@libs/models/shifts';
import { getSingleShift, publishShifts } from '@libs/api/shifts/create';

type InitialState = {
  loadingById: Record<string, boolean>;
  currentShift: Shift | null;
  unassignDialogOpen: boolean;
  assignShift: (shiftId: string, userId: string) => Promise<void>;
  unassignShift: (shiftId: string, data: { reason: string; reasonComment: string }) => Promise<void>;
  cancelShift: (shiftId: string) => Promise<void>;
  deleteShift: (shiftId: string) => Promise<void>;
  shareShifts: (shiftIds: string[], agenciesIds: string[]) => Promise<void>;
  publishShifts: (shiftIds: string[]) => Promise<void>;
  openShiftUnassignDialog: (shiftId: string) => void;
  closeShiftUnassignDialog: () => void;
};

export const useShiftsAssignment = create<InitialState>()((set) => ({
  loadingById: {},
  currentShift: null,
  unassignDialogOpen: false,
  assignShift: async (shiftId: string, userId: string) => {
    set((data) => ({ loadingById: { ...data.loadingById, [shiftId]: true } }));
    try {
      await assignShift(shiftId, userId);
    } finally {
      set((data) => ({
        loadingById: { ...data.loadingById, [shiftId]: false },
      }));
    }
  },
  unassignShift: async (shiftId: string, data) => {
    set((data) => ({ loadingById: { ...data.loadingById, [shiftId]: true } }));
    try {
      await unassignShift(shiftId, data.reason, data.reasonComment);
    } finally {
      set((data) => ({
        loadingById: { ...data.loadingById, [shiftId]: false },
      }));
    }
  },

  cancelShift: async (shiftId: string) => {
    await cancelSingleShift(shiftId);
  },

  deleteShift: async (shiftId: string) => {
    await deleteSingleShift(shiftId);
  },

  shareShifts: async (shiftIds: string[], agenciesIds: string[]) => {
    await shareShifts(shiftIds, agenciesIds);
  },

  publishShifts: async (shiftIds: string[]) => {
    await publishShifts(shiftIds);
  },

  openShiftUnassignDialog: async (shiftId) => {
    const shift = await getSingleShift(shiftId);
    set({
      currentShift: shift,
      unassignDialogOpen: true,
    });
  },
  closeShiftUnassignDialog: () => {
    set({ currentShift: null, unassignDialogOpen: false });
  },
}));
