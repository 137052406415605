import { FormattedMessage } from 'react-intl';
import { useTheme } from '@mui/material/styles';
import Typography from '@mui/material/Typography';
import { useTalentsStore } from '@libs/store/talents';
import { useShiftsFilter } from '@libs/store/shifts';
import { FilterItem } from '@molecules/form/filterMenu';
import { Flex } from '@atoms/layout';

export function useProfessionalsFilterMenu(): FilterItem {
  const theme = useTheme();
  const { talents: data } = useTalentsStore();
  const { getFilter } = useShiftsFilter();
  const filter = getFilter<string[]>('staff');

  return {
    label: <FormattedMessage id="fields.talents" />,
    key: 'staff',
    multiple: true,
    searchable: true,
    valueToLabel: (value) => value.map((v) => getTalentName(data.find((l) => l.id === v))).join(', '),
    children: data.map((item) => {
      const name = getTalentName(item);

      return {
        label: (
          <Flex justifyContent="space-between" width="100%" alignItems="baseline">
            {name}
            <Typography variant="caption" color={theme.palette.text.secondary}>
              {item.nurseQualifications.map((q) => q.abbreviation).join(', ')}
            </Typography>
          </Flex>
        ),
        text: `${name} ${item.nurseQualifications.map((q) => q.abbreviation).join(', ')}`,
        key: item.id,
        selected: filter?.value.includes(item.id) || false,
      };
    }),
  };
}

function getTalentName(talent?: { firstName: string; lastName: string }) {
  if (!talent) {
    return;
  }
  return [talent.firstName, talent.lastName].filter(Boolean).join(' ');
}
