import { Scheduler } from './scheduler/Scheduler';
import { gridConfig, projectConfig, schedulerConfig } from './config';
import '@bryntum/schedulerpro/schedulerpro.stockholm.css';
import './scheduler/scheduler.scss';
import { Stack, Box, useTheme } from '@mui/material';
import { DateHelper } from '@bryntum/schedulerpro';
import { useCallback, useEffect, useState } from 'react';
import { EditShiftDrawer } from './drawers/editShift';
import { useShiftAddEditStore, useShiftsAssignment } from '@libs/store/shifts';
import { useIntl } from 'react-intl';
import { Shift as SchedulerShiftModel } from './scheduler/lib/Shift';
import { Doctor } from './scheduler/lib/Doctor';
import { useLocationStore } from '@libs/store/locations';
import { getTimeZones } from '@vvo/tzdb';
import { useCalendarShiftsStore } from '@libs/store/shifts/calendarStore';
import { SkeletonProgress } from '@molecules/feedback';
import { useCalendarTalentsStore } from '@libs/store/shifts/calendarTalents';
import { useShiftsFilter } from '@libs/store/shifts/shiftsFilter';
import { Shift } from '@libs/models/shifts/shift';
import { useOrgQualificationsStore } from '@libs/store/settings';
import { assignShift } from '@libs/api/shifts';
import { ShiftInProgressDrawer } from './drawers/shiftInProgress';
import { EditSeriesDrawer } from './drawers/editSeries';
import { EditRotationDrawer } from './drawers/editRotation';
import { useShiftSeriesAddEditStore } from '@libs/store/shifts/shiftSeriesAddEditStore';
import { useShiftRotationAddEditStore } from '@libs/store/shifts/shiftRotationAddEditStore';
import { useShiftInProgressStore } from '@libs/store/shifts/shiftInProgressStore';
import { CalendarUnassignDialog } from './drawers/calendarUnassign';
import { parseError } from '@libs/api/errors';
import { useNotification } from '@libs/snackbar';
import { CalendarFilterBar } from './filtersBar';
import { useShareDrawerStore } from '@libs/store/shifts/shiftShareStore';
import { ShareShifts } from './drawers/shareShifts';
import { PublishShiftsDrawer } from './drawers/publishShifts';
import { usePublishDrawerStore } from '@libs/store/shifts/shiftPublishStore';

const EDIT_ALLOWED_STATUSES = ['opened', 'pending', 'reopened', 'unassigned', 'unfilled'];
const UNASSIGN_ALLOWED_STATUSES = ['assigned'];
const CANCEL_ALLOWED_STATUSES = ['reassigned', 'assigned', 'unassigned', 'pending'];
const DELETE_ALLOWED_STATUSES = ['opened'];

export function ShiftsSchedulerPage() {
  const theme = useTheme();
  const intl = useIntl();
  const { showSuccessIntl, showError } = useNotification();
  const { drawerOpen: seriesDrawerOpen, openShiftSeriesDrawer } = useShiftSeriesAddEditStore();
  const { drawerOpen: rotationDrawerOpen, openShiftRotationDrawer } = useShiftRotationAddEditStore();
  const { drawerOpen: inProgressDrawerOpen, openInProgressDrawer } = useShiftInProgressStore();
  const { drawerOpen: shiftEditDrawerOpen, openShiftDrawer } = useShiftAddEditStore();
  const { drawerOpen: shareDrawerOpen, openShiftShareDrawer } = useShareDrawerStore();
  const { drawerOpen: publishDrawerOpen, openShiftPublishDrawer } = usePublishDrawerStore();
  const { unassignDialogOpen, openShiftUnassignDialog, cancelShift, deleteShift } = useShiftsAssignment();
  const { current: currentLocation, currentLocationID, loading: locationsLoading } = useLocationStore();
  const {
    loadCalendarData,
    initiated: isCalendarInitiallyLoaded,
    loading: eventsLoading,
    eventsList,
    loadEvents,
    loadAssignments,
    assignmentsList,
    assignmentsLoading,
    assignmentsInitialLoading,
  } = useCalendarShiftsStore();
  const { loading: talentsLoading, load: loadTalentsList, talents: talentsList } = useCalendarTalentsStore();
  const { activeFilters } = useShiftsFilter();
  const { loading: qualListLoading, load: loadQualifications } = useOrgQualificationsStore();

  const loading = !(
    isCalendarInitiallyLoaded &&
    !locationsLoading &&
    !talentsLoading &&
    !assignmentsInitialLoading &&
    !qualListLoading
  );

  const timezones = getTimeZones();
  const currentTimeZone = timezones.find((tz) => tz.currentTimeFormat === currentLocation?.timezone);

  const [dateRange, setDateRange] = useState({
    startDate: DateHelper.startOf(new Date(), 'week', undefined, 1),
    endDate: DateHelper.add(DateHelper.startOf(new Date(), 'week', undefined, 1), 7, 'days'),
  });

  const [eventsInternal, setEventsInternal] = useState<SchedulerShiftModel[]>([]);

  const assignments = assignmentsList.map((assig, idx) => ({
    id: idx,
    event: assig.shiftId,
    resource: assig.staffId,
  }));

  const talentInternal = talentsList.map((tal) => ({
    id: tal.id,
    avatar: tal.imageUrl,
    requiredRole: 'employee',
    staffingType: tal.staffingType || 'Agency',
    firstName: tal.firstName,
    lastName: tal.lastName,
    phoneNumber: tal.phoneNumber,
    email: tal.email,
    staffingStatus: tal.staffingStatus,
    specialities: tal.specialities,
    lastWorkedTime: tal.lastWorkedTime,
    nurseQualifications: tal.nurseQualifications,
    calendar: 'allday',
  }));

  useEffect(() => {
    loadTalentsList({
      filters: [
        {
          field: 'staffingStatuses',
          value: ['Active'],
        },
      ],
    });
    if (!qualListLoading) {
      loadQualifications();
    }
  }, [loadTalentsList, loadQualifications]);

  const refreshLists = async () => {
    if (!isCalendarInitiallyLoaded && !eventsLoading && currentLocationID) {
      const filters = [...activeFilters];
      filters.push({ key: 'locations', value: [currentLocationID] });
      loadCalendarData(dateRange.startDate, dateRange.endDate);
    }
    if (currentLocationID) {
      const filters = [...activeFilters];
      filters.push({ key: 'locations', value: [currentLocationID] });
      loadEvents(dateRange.startDate, dateRange.endDate, activeFilters);
      loadAssignments(dateRange.startDate, dateRange.endDate, activeFilters);
    }
  };

  useEffect(() => {
    refreshLists();
  }, [dateRange.startDate, dateRange.endDate, currentLocationID, isCalendarInitiallyLoaded, activeFilters]);

  useEffect(() => {
    setEventsInternal(
      eventsList.map((evt) => {
        const duration = Math.floor(((+new Date(evt.endDate) - +new Date(evt.startDate)) / 1000 / 60 / 60) * 10) / 10;
        return {
          id: evt.id,
          // timeZone: evt.timeZone,
          startDate: new Date(evt.startDate).toISOString(),
          endDate: new Date(evt.endDate).toISOString(),
          status: evt.status,
          nurseQualifications: evt.nurseQualifications,
          otherQualifications: evt.otherQualifications,
          requiredRole: 'employee',
          isUrgent: evt.isUrgent,
          manuallyScheduled: true,
          actualStartDate: evt.startDate,
          actualEndDate: evt.endDate,
          grouping: `${DateHelper.format(new Date(evt.startDate), 'MMM D, YYYY')}`,
          duration: duration > 0 ? duration : 0.1,
        };
      }) as unknown as SchedulerShiftModel[],
    );
  }, [eventsList]);

  const handleTodayClick = () => {
    setDateRange({
      startDate: DateHelper.startOf(new Date(), 'week', undefined, 1),
      endDate: DateHelper.add(DateHelper.startOf(new Date(), 'week', undefined, 1), 7, 'days'),
    });
  };
  const handleWeekBackClick = () => {
    setDateRange({
      startDate: DateHelper.add(dateRange.startDate, -7, 'days'),
      endDate: dateRange.startDate,
    });
  };
  const handleWeekForwardClick = () => {
    setDateRange({
      startDate: dateRange.endDate,
      endDate: DateHelper.add(dateRange.endDate, 7, 'days'),
    });
  };

  const handlePublishShift = () => {
    openShiftPublishDrawer();
  };

  const handleShareShift = () => {
    openShiftShareDrawer();
  };

  const handleNewShift = () => {
    openShiftDrawer();
  };

  const openEditPopup = (shift: SchedulerShiftModel) => {
    if (EDIT_ALLOWED_STATUSES.includes(shift.status.toLowerCase())) {
      // open edit mode drawer
      shift.id && openShiftDrawer(shift.id + ''); // edit mode
    }
  };
  const handleShiftDetailsOpen = (shift: SchedulerShiftModel) => {
    openEditPopup(shift); // try to open up edit

    if (['Assigned', 'InProgress', 'Completed'].includes(shift.status)) {
      // open "in progress" view drawer
      // openInProgressDrawer();
    }
  };

  const handleCreateRotation = () => {
    openShiftRotationDrawer();
  };
  const handleCreateSeries = () => {
    openShiftSeriesDrawer();
  };

  const assignDebounced = debounce_leading(
    (async (resource: Doctor, shifts: SchedulerShiftModel[]) => {
      for (let i = 0; i < shifts.length; i++) {
        await assignShift(shifts[i].id as string, resource.id as string);
      }
      await shifts[0].project.commitAsync();
      refreshLists();
    }) as () => Promise<void>,
    500,
  ) as (resource: Doctor, shifts: SchedulerShiftModel[]) => void;

  const handleAssign = useCallback(
    (resource: Doctor, shifts: SchedulerShiftModel[]) => {
      assignDebounced(resource, shifts);
    },
    [useCallback],
  );

  const handleUnassign = (shift: SchedulerShiftModel) => {
    openShiftUnassignDialog(shift.id as string);
  };

  const handleCancel = (shift: SchedulerShiftModel) => {
    cancelShift(shift.id as string)
      .then(() => {
        refreshLists();
        showSuccessIntl('shifts.unassignSuccessful');
      })
      .catch((error: unknown) => showError(parseError(error).message));
  };

  const handleDelete = (shift: SchedulerShiftModel) => {
    deleteShift(shift.id as string)
      .then(() => {
        refreshLists();
        showSuccessIntl('shifts.deleteSuccessful');
      })
      .catch((error: unknown) => showError(parseError(error).message));
  };

  return (
    <>
      <CalendarFilterBar
        displayedDate={dateRange.startDate}
        onToday={handleTodayClick}
        onWeekBack={handleWeekBackClick}
        onWeekForward={handleWeekForwardClick}
        onNewShift={handleNewShift}
        onNewRotation={handleCreateRotation}
        onNewSeries={handleCreateSeries}
        onListRefresh={refreshLists}
        onPublish={handlePublishShift}
        onShare={handleShareShift}
      />
      {loading && (
        <Stack direction={'column'} height={'100%'}>
          <Box flex={1} />
          <SkeletonProgress show={loading} />
          <Box flex={1} />
        </Stack>
      )}
      <Scheduler
        loading={loading}
        onAssign={handleAssign}
        gridConfig={{
          ...gridConfig,
          cellMenuFeature: {
            disabled: false,
            processItems: ({ record, items }) => {
              const shift = record as SchedulerShiftModel;
              if (!EDIT_ALLOWED_STATUSES.includes(shift.status.toLowerCase())) {
                (items.editEvent as any).disabled = true;
              }
              if (!CANCEL_ALLOWED_STATUSES.includes(shift.status.toLowerCase())) {
                (items.cancel as any).disabled = true;
              }
              if (!DELETE_ALLOWED_STATUSES.includes(shift.status.toLowerCase())) {
                (items.removeRow as any).disabled = true;
              }
            },
            items: {
              editEvent: {
                text: 'Edit',
                disabled: false,
                weight: 50,
                onItem: ({ record }: { record: SchedulerShiftModel }) => {
                  openEditPopup(record);
                },
              },
              cut: false,
              copy: false,
              paste: false,
              filterMenu: false,
              splitGrid: false,
              splitHorizontally: false,
              splitVertically: false,
              splitBoth: false,
              search: false,
              cancel: {
                text: 'Cancel',
                disabled: false,
                onItem: ({ record }: { record: SchedulerShiftModel }) => {
                  handleCancel(record);
                },
              },
              removeRow: {
                text: 'Delete',
                disabled: false,
                onItem: ({ record }: { record: SchedulerShiftModel }) => {
                  handleDelete(record);
                },
              },
              splitEvent: false,
              renameSegment: false,
            },
          },
          onCellDblClick: (evt) => {
            handleShiftDetailsOpen(evt.record as SchedulerShiftModel);
          },
        }}
        // timeZone={currentTimeZone?.name}
        projectConfig={{
          ...projectConfig,
          resources: talentInternal,
          events: eventsInternal,
          assignments,
          timeRanges: [
            {
              id: 1, // will highlight the Today
              cls: 'todayColumnHighlight',
              name: DateHelper.format(new Date(), 'ddd DD'),
              startDate: DateHelper.startOf(new Date(), 'day'),
              duration: 1,
              durationUnit: 'day',
              style: 'background: #ecf2fc',
            },
          ],
        }}
        schedulerConfig={{
          ...schedulerConfig,
          startDate: dateRange.startDate,
          timeZone: currentTimeZone?.currentTimeOffsetInMinutes,
          endDate: dateRange.endDate,
          onEventDblClick: (evt) => {
            // evt.resourceRecord <- here - the nurse record could be get
            handleShiftDetailsOpen(evt.eventRecord as SchedulerShiftModel);
          },
          eventMenuFeature: {
            disabled: false,
            processItems: ({ eventRecord, items }) => {
              const shift = eventRecord as SchedulerShiftModel;
              if (!EDIT_ALLOWED_STATUSES.includes(shift.status.toLowerCase())) {
                (items.editEvent as any).disabled = true;
              }
              if (!UNASSIGN_ALLOWED_STATUSES.includes(shift.status.toLowerCase())) {
                (items.unassignEvent as any).disabled = true;
              }
              if (!CANCEL_ALLOWED_STATUSES.includes(shift.status.toLowerCase())) {
                (items.deleteEvent as any).disabled = true;
              }
            },
            items: {
              editEvent: {
                text: 'Edit',
                disabled: false,
                onItem: ({ eventRecord }: { eventRecord: SchedulerShiftModel }) => {
                  openEditPopup(eventRecord);
                },
              },
              unassignEvent: {
                text: 'Unassign',
                disabled: false,
                onItem: ({ eventRecord }: { eventRecord: SchedulerShiftModel }) => {
                  handleUnassign(eventRecord);
                },
              },
              cutEvent: false,
              copyEvent: false,
              deleteEvent: {
                text: 'Cancel',
                disabled: false,
                onItem: ({ eventRecord }: { eventRecord: SchedulerShiftModel }) => {
                  handleCancel(eventRecord);
                },
              },
              splitEvent: false,
              renameSegment: false,
            },
          },
        }}
      />
      {shiftEditDrawerOpen && (
        <EditShiftDrawer
          onSaveCall={() => {
            refreshLists();
          }}
        />
      )}
      {inProgressDrawerOpen && (
        <ShiftInProgressDrawer
          onSaveCall={() => {
            refreshLists();
          }}
        />
      )}
      {seriesDrawerOpen && (
        <EditSeriesDrawer
          onSaveCall={() => {
            refreshLists();
          }}
        />
      )}
      {rotationDrawerOpen && (
        <EditRotationDrawer
          onSaveCall={() => {
            refreshLists();
          }}
        />
      )}
      {unassignDialogOpen && (
        <CalendarUnassignDialog
          onAction={() => {
            refreshLists();
          }}
        />
      )}
      {shareDrawerOpen && (
        <ShareShifts
          onSaveCall={() => {
            refreshLists();
          }}
        />
      )}
      {publishDrawerOpen && (
        <PublishShiftsDrawer
          onSaveCall={() => {
            refreshLists();
          }}
        />
      )}
    </>
  );
}

function debounce_leading(func: () => void, timeout = 300) {
  let timer: NodeJS.Timeout | undefined;
  return (...args: []) => {
    if (!timer) {
      timer = setTimeout(() => {
        clearTimeout(timer);
        timer = undefined;
      }, timeout);
      func.apply(func, args);
    }
  };
}
