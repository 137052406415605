import { useEffect, useState } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import dayjs, { Dayjs } from 'dayjs';
import { Box, ClosableDrawer, PrimaryPaper } from '@atoms/layout';
import { Chip, Grid, Stack, styled, Typography } from '@mui/material';
import { Button } from '@atoms/buttons';
import { DateHelper } from '@bryntum/schedulerpro';
import { ShiftList } from '@libs/models/shifts/shift';
import { useNotification } from '@libs/snackbar';
import { useShareDrawerStore } from '@libs/store/shifts/shiftShareStore';
import { ShiftsIcon } from '@atoms/icons';
import { DataGrid, EmptyListPlaceholder } from '@molecules/dataDisplay';
import { useShiftListColumns } from './shiftListColumns';
import { Flex } from '@atoms/layout';
import { GridRowSelectionModel } from '@mui/x-data-grid';
import { FilterMultiSelect, renderCheckboxOption, SearchField } from '@atoms/inputs';
import { StartDateRangeFilterInputControlled } from '@organisms/shifts/startDateRangeFilterInput';
import { StaffingPartnerList } from '@libs/models/staffingPartners';
import { useStaffingPartnersShortListColumns } from '@pages/staffingPartners/columns';
import { useStaffingPartnersList } from '@libs/store/staffingPartners';
import { useShiftsAssignment } from '@libs/store/shifts';

type ShiftInProgressDrawerProps = {
  onSaveCall?: () => void;
};
export function ShareShifts({ onSaveCall }: ShiftInProgressDrawerProps) {
  const intl = useIntl();
  const { showError } = useNotification();

  const {
    drawerOpen,
    loading: shiftLoading,
    shiftsToShare,
    closeShiftShareDrawer,
    loadSharableShifts,
  } = useShareDrawerStore();
  const { staffingPartners, loadingList: staffingPartnersLoading, loadStaffingPartners } = useStaffingPartnersList();
  const { shareShifts } = useShiftsAssignment();

  const [selectedShifts, setSelectedShifts] = useState<GridRowSelectionModel>([]);
  const [selectedAgencies, setSelectedAgencies] = useState<GridRowSelectionModel>([]);
  const [shiftStatusFilter, setShiftStatusFilter] = useState<string[]>([]);
  const [shiftsDateFilter, setShiftsDateFilter] = useState<DateFilterValue | null>();

  const localizationSubset = 'shifts.shiftsShareDrawer';
  const open = drawerOpen;

  const loading = shiftLoading || staffingPartnersLoading;

  const [page, setPage] = useState<string>('shifts');

  useEffect(() => {
    loadSharableShifts(shiftsDateFilter?.value || null, shiftStatusFilter);
  }, [loadSharableShifts]);

  useEffect(() => {
    loadStaffingPartners([]);
  }, [loadStaffingPartners]);

  const handleClose = () => {
    closeShiftShareDrawer();
  };

  const handleSubmit = async () => {
    try {
      await shareShifts(selectedShifts as string[], selectedAgencies as string[]);
      onSaveCall && onSaveCall();
      handleClose();
    } catch (error) {
      if (error instanceof Error) {
        showError(error.message);
      }
    }
  };

  const handleNextPage = async () => {
    if (page === 'shifts') {
      setPage('agencies');
    } else {
      await handleSubmit();
    }
  };
  const handlePrevPage = async () => {
    if (page === 'agencies') {
      setPage('shifts');
    } else {
      handleClose();
    }
  };

  return (
    <ClosableDrawer
      open={open}
      loading={loading}
      onClose={handleClose}
      controls={
        <Stack direction={'row-reverse'} spacing={2} sx={(theme) => ({ paddingBottom: theme.spacing(1) })}>
          <MenuButton variant="contained" onClick={handleNextPage} disabled={loading || !selectedShifts.length}>
            <FormattedMessage
              id={`${localizationSubset}.menuButtons.${page === 'agencies' ? 'share' : 'proceed'}.label`}
            />
          </MenuButton>
          <Box flex={1} />
          <MenuButton
            onClick={handlePrevPage}
            sx={(theme) => ({ color: theme.palette.text.secondary })}
            disabled={loading}
          >
            <FormattedMessage id={`${localizationSubset}.menuButtons.back.label`} />
          </MenuButton>
        </Stack>
      }
    >
      <Stack direction={'column'} sx={{ height: '100%' }} spacing={2} width={{ xs: '300px', sm: '600px' }}>
        {/* Header */}
        <Stack direction={'row'}>
          <Typography variant="h5">
            <FormattedMessage id={`${localizationSubset}.dialogHeading`} />
          </Typography>
          <Box flex={1} />
        </Stack>

        {page === 'shifts' && (
          <ShiftsPage
            loading={shiftLoading}
            rows={shiftsToShare}
            localizationSubset={localizationSubset}
            onSelectionChange={setSelectedShifts}
            onStatusFilterChange={setShiftStatusFilter}
            onDateFilterChange={setShiftsDateFilter}
          />
        )}
        {page === 'agencies' && (
          <AgenciesPage
            loading={staffingPartnersLoading}
            rows={staffingPartners}
            onSelectionChange={setSelectedAgencies}
            shiftsSelected={selectedShifts.length}
            localizationSubset={localizationSubset}
          />
        )}
      </Stack>
    </ClosableDrawer>
  );
}

type DateFilterValue = { key: string; value: { from: Dayjs; to: Dayjs } };
type ShiftsPageProps = {
  rows: ShiftList[];
  loading: boolean;
  localizationSubset: string;
  onSelectionChange: (selection: GridRowSelectionModel) => void;
  onStatusFilterChange: (values: string[]) => void;
  onDateFilterChange: (value: DateFilterValue | null) => void;
};

function ShiftsPage({
  rows,
  loading,
  localizationSubset,
  onSelectionChange,
  onStatusFilterChange,
  onDateFilterChange,
}: ShiftsPageProps) {
  const intl = useIntl();
  const [search, setSearch] = useState<string>('');
  const [statusFilter, setStatusFilter] = useState<string[]>([]);
  const [dateFilter, setDateFilter] = useState<DateFilterValue | null>({
    key: '',
    value: {
      from: dayjs(DateHelper.startOf(new Date(), 'week', undefined, 1).toISOString()),
      to: dayjs(DateHelper.add(DateHelper.startOf(new Date(), 'week', undefined, 1), 7, 'days').toISOString()),
    },
  });
  const [rowSelectionModel, setRowSelectionModel] = useState<GridRowSelectionModel>([]);
  const dateLabel = [dateFilter?.value.from?.format('MMM D, YYYY'), dateFilter?.value.to?.format('MMM D, YYYY')]
    .filter(Boolean)
    .join(' - ');

  useEffect(() => {
    onDateFilterChange && onDateFilterChange(dateFilter);
  }, [dateFilter]);
  useEffect(() => {
    onStatusFilterChange && onStatusFilterChange(statusFilter);
  }, [statusFilter]);
  useEffect(() => {
    onSelectionChange && onSelectionChange(rowSelectionModel);
  }, [rowSelectionModel]);

  const localizationSet = `${localizationSubset}.shiftsPickPage`;

  const statusFilterOptions = ['Unassigned', 'Pending'].map((status) => ({
    label: status,
    value: status,
  }));

  return (
    <PrimaryPaper sx={{ mt: 1 }}>
      <Stack direction={'column'} spacing={2} sx={{ marginBottom: '16px', marginTop: '16px' }}>
        <Grid container>
          <Grid item xs={4}>
            <StartDateRangeFilterInputControlled
              disabled={loading}
              onClear={() => setDateFilter(null)}
              onSetFilter={(filter) => setDateFilter(filter as DateFilterValue)}
              dateLabel={dateLabel}
              dateValue={dateFilter?.value}
            />
          </Grid>
          <Grid item xs={4}>
            <FilterMultiSelect
              size="small"
              disabled={loading}
              options={statusFilterOptions}
              inlineLabel
              fullWidth
              label={`${intl.formatMessage({ id: 'filters.statuses.label' })}:`}
              onApply={(v) => setStatusFilter(v)}
              onClear={() => setStatusFilter([])}
              value={statusFilter ?? []}
              renderOption={renderCheckboxOption}
            />
          </Grid>
          <Grid item xs={4}>
            <Stack direction={'row'} alignContent={'end'} justifyContent={'end'} sx={{ marginTop: '4px' }}>
              <Chip
                disabled={loading}
                label={intl.formatMessage(
                  { id: `${localizationSet}.shiftsSelectionCounter` },
                  { amount: rowSelectionModel.length },
                )}
              />
            </Stack>
          </Grid>
        </Grid>
        <Flex height="100%" minHeight={0}>
          <DataGrid
            rows={rows}
            loading={loading}
            rowHeight={64}
            columns={useShiftListColumns()}
            autoPageSize
            hideFooter
            disableVirtualization={false}
            checkboxSelection
            filterModel={{
              items: [{ field: 'name', operator: 'contains', value: search }],
            }}
            onRowSelectionModelChange={(newRowSelectionModel) => {
              setRowSelectionModel(newRowSelectionModel);
            }}
            rowSelectionModel={rowSelectionModel}
            noRowsOverlay={
              <EmptyListPlaceholder
                icon={<ShiftsIcon active />}
                title={<FormattedMessage id="shifts.table.noRows.title" />}
                subtitle={<FormattedMessage id="shifts.table.noRows.subtitle" />}
              />
            }
          />
        </Flex>
      </Stack>
    </PrimaryPaper>
  );
}

type AgenciesPageProps = {
  loading: boolean;
  shiftsSelected: number;
  localizationSubset: string;
  rows: StaffingPartnerList[];
  onSelectionChange: (selection: GridRowSelectionModel) => void;
};

function AgenciesPage({
  shiftsSelected,
  localizationSubset,
  rows,
  onSelectionChange,
  loading = false,
}: AgenciesPageProps) {
  const intl = useIntl();
  const [search, setSearch] = useState<string>('');
  const [rowSelectionModel, setRowSelectionModel] = useState<GridRowSelectionModel>([]);

  const localizationSet = `${localizationSubset}.agencyPickPage`;

  useEffect(() => {
    onSelectionChange && onSelectionChange(rowSelectionModel);
  }, [rowSelectionModel]);

  const rowsFiltered = search
    ? rows.filter((row) => {
        const record = JSON.stringify(row);
        if (record.includes(search)) {
          return true;
        }
      })
    : rows;

  return (
    <PrimaryPaper sx={{ mt: 1 }}>
      <Stack direction={'column'} spacing={2} sx={{ marginBottom: '16px', marginTop: '16px' }}>
        <Grid container>
          <Grid item xs={4}>
            <SearchField
              disabled={loading}
              sx={{ width: 260, flexShrink: 0 }}
              value={search}
              onChange={(elem: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) =>
                setSearch(elem.target.value)
              }
            />
          </Grid>
          <Grid item xs={5}>
            <Stack direction={'row'} alignContent={'end'} justifyContent={'end'} sx={{ marginTop: '4px' }}>
              <Chip
                disabled={loading}
                color="primary"
                label={intl.formatMessage(
                  { id: `${localizationSet}.shiftsSelectionCounter` },
                  { amount: shiftsSelected },
                )}
              />
            </Stack>
          </Grid>
          <Grid item xs={3}>
            <Stack direction={'row'} alignContent={'end'} justifyContent={'end'} sx={{ marginTop: '4px' }}>
              <Chip
                disabled={loading}
                label={intl.formatMessage(
                  { id: `${localizationSet}.agenciesSelectionCounter` },
                  { amount: rowSelectionModel.length },
                )}
              />
            </Stack>
          </Grid>
        </Grid>
        <Flex height="100%" minHeight={0}>
          <DataGrid
            rows={rowsFiltered}
            loading={loading}
            rowHeight={64}
            columns={useStaffingPartnersShortListColumns()}
            autoPageSize
            hideFooter
            disableVirtualization={false}
            checkboxSelection
            filterModel={{
              items: [{ field: 'name', operator: 'contains', value: search }],
            }}
            onRowSelectionModelChange={(newRowSelectionModel) => {
              setRowSelectionModel(newRowSelectionModel);
            }}
            rowSelectionModel={rowSelectionModel}
            noRowsOverlay={
              <EmptyListPlaceholder
                icon={<ShiftsIcon active />}
                title={<FormattedMessage id="shifts.table.noRows.title" />}
                subtitle={<FormattedMessage id="shifts.table.noRows.subtitle" />}
              />
            }
          />
        </Flex>
      </Stack>
    </PrimaryPaper>
  );
}

const MenuButton = styled(Button)(({ theme }) => ({
  minWidth: theme.spacing(16.75),
}));
