import { StaffingPartnerList } from '@libs/models/staffingPartners';
import { getFetchMock } from './mocks/fetch';
import { Filter } from '@libs/models/common';

export async function fetchStaffingPartnersList(filters: Filter[]): Promise<StaffingPartnerList[]> {
  const mock = getFetchMock().filter((partner) => {
    return filters && filters.length
      ? filters.some((filter) => {
          if (filter.key === 'relations') {
            const value = filter.value as string[];
            if (value.includes(partner.relationType)) {
              return true;
            }
          }
          if (filter.key === 'statuses') {
            const value = filter.value as string[];
            if (value.includes(partner.invitationStatus)) {
              return true;
            }
          }
          if (filter.key === 'search') {
            const value = filter.value as string;
            const record = JSON.stringify(partner);
            if (record.includes(value)) {
              return true;
            }
          }
        })
      : true;
  });
  const fake = async () =>
    new Promise<StaffingPartnerList[]>((resolve) => {
      setTimeout(() => {
        resolve(mock);
      }, 1000);
    });
  return await fake();
}
